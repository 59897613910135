import React from 'react';

export const RoadShareIcon = ({ fill = '#454545' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.48149 9.30158C2.86145 9.74487 3.41615 10 4 10C4.58385 10 5.13855 9.74487 5.51851 9.30158L5.63343 9.16765C6.8466 7.75446 8 6.41089 8 4.22222C8 2.09378 6.40214 0 4 0C1.59786 0 0 2.09378 0 4.22222C0 6.41089 1.1534 7.75446 2.36657 9.16765L2.48149 9.30158ZM6 4.22222C6 5.06997 5.75811 5.70272 5.27435 6.41185C4.95498 6.88 4.5302 7.38143 4 8C3.4698 7.38143 3.04502 6.88 2.72565 6.41185C2.24189 5.70272 2 5.06997 2 4.22222C2 2.99492 2.89543 2 4 2C5.10457 2 6 2.99492 6 4.22222ZM7 9C7 8.44771 7.44772 8 8 8H8.95312C11.1982 8 13 9.84554 13 12.0705C13 14.2696 11.2192 16.0938 9 16.0938C7.89543 16.0938 7 16.9892 7 18.0938V19.0469C7 20.6778 8.32216 22 9.95312 22H14C14.5523 22 15 22.4477 15 23C15 23.5523 14.5523 24 14 24H9.95312C7.21759 24 5 21.7824 5 19.0469V18.0938C5 15.8846 6.79086 14.0938 9 14.0938C10.0946 14.0938 11 13.1851 11 12.0705C11 10.93 10.0736 10 8.95312 10H8C7.44772 10 7 9.55229 7 9ZM17.5603 23.3883C17.9373 23.7792 18.457 24 19 24C19.543 24 20.0627 23.7792 20.4397 23.3883L20.5974 23.2248C22.4116 21.3457 24 19.7005 24 16.963C24 14.1986 21.7379 12 19 12C16.2621 12 14 14.1986 14 16.963C14 19.7005 15.5884 21.3457 17.4026 23.2248L17.5603 23.3883ZM19 22C18.4748 21.4553 18.0185 20.9788 17.6312 20.5381C16.5437 19.3008 16 18.3467 16 16.963C16 15.3266 17.3431 14 19 14C20.6569 14 22 15.3266 22 16.963C22 18.3467 21.4563 19.3008 20.3688 20.5381C19.9815 20.9788 19.5252 21.4553 19 22ZM19 18C19.5523 18 20 17.5523 20 17C20 16.4477 19.5523 16 19 16C18.4477 16 18 16.4477 18 17C18 17.5523 18.4477 18 19 18Z"
      fill={fill}
    />
  </svg>
);
