import React, { useEffect } from 'react';
import { Select, Divider } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { StyledCard, StyledUpload } from './CreateWaiver.styles';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface WaiverSelectorProps {
  selectedWaiver: string;
  waiverTemplates: Array<any>;
  updateFile: (info: any) => void;
  setSelectedWaiver: (value: string) => void;
  setWaiverSelected: (value: boolean) => void;
  hideSelector?: boolean;
}

const WaiverSelector: React.FC<WaiverSelectorProps> = ({
  selectedWaiver,
  setSelectedWaiver,
  updateFile,
  setWaiverSelected,
  waiverTemplates,
  hideSelector,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!hideSelector && (
        <>
          <StyledCard title={t('common:selectWaiver')}>
            <Select
              style={{ width: '100%' }}
              placeholder="Choose a waiver"
              value={selectedWaiver}
              onChange={(value) => {
                setSelectedWaiver(value);
                setWaiverSelected(true);
              }}
            >
              {waiverTemplates &&
                waiverTemplates.length > 0 &&
                waiverTemplates.map((template, index) => {
                  // Extract the template name from the URL
                  const templateName = template?.url?.split('/').pop();

                  if (templateName) {
                    // Split the template name into parts to extract name and timestamp
                    const parts = templateName.split('-');
                    const name = parts.slice(0, -1).join('-'); // Combine all but the last part for the name
                    const timestamp = parts.slice(-1)[0]; // Extract the timestamp

                    // Convert the timestamp to a readable date format
                    const date = new Date(
                      parseInt(timestamp)
                    ).toLocaleDateString();

                    return (
                      <Option key={template._id} value={template?._id}>
                        {t('common:waiverTemplate')} {index + 1} - {name} -{' '}
                        {date}
                      </Option>
                    );
                  }

                  // Handle cases where the URL format is unexpected
                  return (
                    <Option key={template._id} value={template._id}>
                      {t('common:waiverTemplate')} {index + 1} - Invalid Data
                    </Option>
                  );
                })}
            </Select>
          </StyledCard>

          <Divider dashed style={{ borderColor: '#FF950A' }}>
            {t('common:or')}
          </Divider>
        </>
      )}

      <StyledCard title={t('common:uploadWaiver')}>
        <StyledUpload
          name="file"
          multiple={false}
          onChange={updateFile}
          accept="application/pdf"
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p>{t('common:clickToUploadFile')}</p>
          <p>{t('createWaiver:onlyPdfOrDocFiles')}</p>
        </StyledUpload>
      </StyledCard>
    </>
  );
};

export default WaiverSelector;
