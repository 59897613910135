import React, { useState, useEffect, useCallback } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Drawer from 'components/Drawer';
import { ErrorMessage } from './ClubWaiver.styles';
import WaiverSelector from 'containers/CreateWaiver/WaiverSelector';
import SelectedWaiverPreview from 'containers/CreateWaiver/SelectedWaiverPreview';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LoadingComponent } from 'components';

const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const ClubWaiverDrawer = ({
  form,
  open,
  setOpen,
  initialValues,
  groupId,
  onBack,
  selectedTemplate,
  isEdit,
  onChange,
  variant,
}) => {
  const { t } = useTranslation('createWaiver');
  const userInfo = useSelector((state) => state?.user?.userDetails);
  const [selectedWaiver, setSelectedWaiver] = useState('');
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [waiverSelected, setWaiverSelected] = useState(false);
  const [error, setError] = useState(null);
  const { action } = fetchApi();
  const [waiverTemplates, setWaiverTemplates] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('variant==>', variant);
    console.log('isEdit==>', isEdit);
  }, [variant]);

  useEffect(() => {
    if (selectedTemplate && selectedTemplate?.url) {
      setWaiverSelected(true);
      setPreviewUrl(selectedTemplate?.url);
    }
    if (selectedTemplate && !selectedTemplate?.url) {
      handleWaiverSelect(selectedTemplate);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    const fetchWaiverTemplates = async () => {
      try {
        const response = await action(API_NAME.GET_WAIVER_TEMPLATES, {
          params: {
            undefined,
            id: initialValues?.group ? initialValues?.group : userInfo?._id,
          },
        });

        if (response?.waiverTemplates) {
          setWaiverTemplates(response?.waiverTemplates);
        }
      } catch (error) {
        console.error('Failed to fetch waiver templates:', error);
      }
    };

    fetchWaiverTemplates();
  }, [action, userInfo]);

  const updateFile = (info) => {
    const { status, originFileObj } = info.file;

    if (status === 'done') {
      setFile(originFileObj);
      setPreviewUrl(URL.createObjectURL(originFileObj));
      setWaiverSelected(true);
      resetSelections();
    } else if (status === 'error') {
      setError(`${info.file.name} ${t('uploadFailed')}`);
    }
  };

  // const handleWaiverSelect = (value: string) => {
  //   setSelectedWaiver(value);
  //   setPreviewUrl(value);
  //   setWaiverSelected(true);
  // };

  const handleWaiverSelect = (value) => {
    if (value?.content) {
      // Convert base64 content to Blob and create an object URL
      const binaryString = atob(value.content); // Decode base64
      const byteArray = Uint8Array.from(binaryString, (char) =>
        char.charCodeAt(0)
      ); // Convert to byte array
      const blob = new Blob([byteArray], { type: value.type }); // Create Blob
      const url = URL.createObjectURL(blob); // Generate object URL

      setPreviewUrl(url); // Set preview URL
    } else {
      setPreviewUrl(value); // Directly use value if it's a URL
    }
    setSelectedWaiver(value);
    setWaiverSelected(true);
  };

  const resetSelections = () => {
    setSelectedWaiver('');
    setError(null);
  };

  const saveWaiver = useCallback(
    async (body: any, groupId: string, callback = () => {}) => {
      setLoading(true);
      try {
        const response = await action(
          API_NAME.CLUB_WAIVER_POST,
          {
            params: {
              groupId,
            },
            body,
          },
          true
        );
        if (response) {
          setOpen(false);
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoading(false);
        return;
      }
    },
    []
  );

  const editWaiver = useCallback(
    async (body: any, groupId: string, templateId: string) => {
      setLoading(true);
      try {
        const response = await action(
          API_NAME.EDIT_CLUB_WAIVER,
          {
            params: {
              groupId,
              templateId,
            },
            body,
          },
          true
        );
        if (response) {
          setOpen(false);
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoading(false);
        return;
      }
    },
    []
  );

  const prepareWaiverData = async () => {
    if (!waiverSelected || (!file && !selectedWaiver)) {
      setError(t('selectOrUploadWaiver'));
      return null;
    }

    if (file) {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        setError(t('onlyPdfOrDocFiles'));
        return null;
      }

      const base64String = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve((reader.result as string).split(',')[1]);
        reader.onerror = () => reject('File reading error');
        reader.readAsDataURL(file);
      });

      return {
        waiverFile: {
          content: base64String,
          name: file.name,
          type: file.type,
          size: file.size,
        },
      };
    }

    return {
      type: 'waiver',
      waiverTemplateId: selectedWaiver,
    };
  };

  const handleSubmit = async () => {
    try {
      const waiverData = await prepareWaiverData();
      if (waiverData) {
        if (isEdit) editWaiver(waiverData, groupId, selectedTemplate?._id);
        else saveWaiver(waiverData, groupId);
        setError(null);
      }
    } catch (err) {
      console.error('Submission error:', err);
      setError(t('submissionError'));
    }
  };

  const addWaiverToForm = async () => {
    console.log('addToForm');

    const waiverData = await prepareWaiverData();
    try {
      if (waiverData) {
        onChange &&
          onChange(waiverData?.waiverFile || waiverData?.waiverTemplateId);
        setOpen(false);
      }
    } catch (error) {
      console.error('Submission error:', error);
      setError(t('submissionError'));
    }
  };

  const handleCancel = () => {
    setWaiverSelected(false);
    setFile(null);
    setSelectedWaiver('');
    setPreviewUrl('');
    setError(null);
  };

  return (
    <Drawer
      open={open}
      onDrawerClose={() => setOpen(false)}
      headerText={isEdit ? t('event:editWaiver') : t('common:uploadWaiver')}
      onIconClick={onBack}
      submitText={isEdit ? t('commond:edit') : t('submitText')}
      onSubmit={variant === 'create' ? addWaiverToForm : handleSubmit}
    >
      {loading ? (
        <LoadingComponent
          fullWidth
          loaderSize={50}
          color="#C2C2C2"
          variant="spin"
        />
      ) : (
        <>
          {error && (
            <ErrorMessage>
              <ExclamationCircleOutlined /> {error}
            </ErrorMessage>
          )}

          {!waiverSelected ? (
            <WaiverSelector
              hideSelector={true}
              updateFile={updateFile}
              selectedWaiver={selectedWaiver}
              waiverTemplates={waiverTemplates}
              setSelectedWaiver={handleWaiverSelect}
              setWaiverSelected={setWaiverSelected}
            />
          ) : (
            <SelectedWaiverPreview
              previewUrl={previewUrl}
              handleCancel={handleCancel}
            />
          )}
        </>
      )}
    </Drawer>
  );
};

export default ClubWaiverDrawer;
