import React, { useEffect } from 'react';
import { Button } from 'antd';
import { EyeOutlined, CloseOutlined } from '@ant-design/icons';
import { StyledCard, FileName, ButtonContainer } from './CreateWaiver.styles';
import { useTranslation } from 'react-i18next';

interface SelectedWaiverPreviewProps {
  previewUrl: string;
  handleCancel?: () => void;
}

const SelectedWaiverPreview: React.FC<SelectedWaiverPreviewProps> = ({
  previewUrl,
  handleCancel,
}) => {
  const { t } = useTranslation();

  return (
    <StyledCard title={t('createWaiver:selectedWaiver')}>
      <ButtonContainer>
        <Button
          icon={<EyeOutlined />}
          disabled={!previewUrl}
          onClick={() => window.open(previewUrl, '_blank')}
        >
          {t('common:viewFull')}
        </Button>

        {handleCancel && (
          <Button danger icon={<CloseOutlined />} onClick={handleCancel}>
            {t('common:cancel')}
          </Button>
        )}
      </ButtonContainer>

      {previewUrl && (
        <iframe
          title="Preview"
          src={previewUrl}
          style={{ width: '100%', height: '100%', border: 'none' }}
        />
      )}
    </StyledCard>
  );
};

export default SelectedWaiverPreview;
