import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from 'components/Drawer';
import { ICreatePaymentFormDrawer } from './IPaymentFormDrawer';
import API_NAME from 'services/api';
import {
  FieldCollection,
  IFieldCollection,
} from 'components/DynamicFormComponents/FieldCollection/FieldCollection';

import Text from 'components/common/Text';
import { Select as CurrencySelector } from 'antd';
import {
  Label,
  SectionHeader,
  SectionWrapper,
  Select,
  SelectIconWrap,
  SelectWrapper,
  SeparateSettingsHeader,
  TimeZoneText,
  ValidationErrorWrapper,
} from './CreatePaymentFormDrawer.styles';
import { InputDescriptionField, LoadingComponent, Pill } from 'components';
import { Field } from 'react-final-form';
import ScheduleTime from 'components/ScheduleTime';
import { getDateInOriginalTimezone } from 'utils/date';
import moment, { Moment } from 'moment';
import { mapTimezoneOffsetToMinutes } from 'utils/time';
import { WorldIcon } from 'public/assets/icons';
import { minimalTimezoneSet } from 'node_modules/compact-timezone-list';
import { format } from 'date-fns';
import momentTimezone from 'moment-timezone';
import { composeValidators, required } from 'utils/validators';
import { FieldDetailsModal } from 'components/Modals/FieldDetailsModal/FieldDetailsModal';
import {
  FormFieldType,
  FormFieldTypeLabels,
  IFormField,
} from 'components/DynamicFormComponents/FormField/FormField';

import fetchApi from 'hooks/fetchApi';
import AccountSelectionDrawer from 'containers/AccountSelectionDrawer';
import { getCollectionByEnum, PaymentCollection } from './PaymentCollection';
import { FormNotifyModal } from 'components/Modals/FormNotifyModal/FormNotifyModal';
import { AttenderStatus } from 'types';

interface PaymentFormData {
  description: string;
  metadata?: {
    currency?: string;
  };
  openingDate: number;
  closingDate: number;
  refundDeadLine: number;
  timeZone: string;
  fieldCollections: IFieldCollection[];
  type: 'payment';
  id?: string;
}

const CURRENCIES = [
  { code: 'USD', name: 'US Dollar' },
  { code: 'EUR', name: 'Euro' },
  { code: 'GBP', name: 'British Pound' },
  { code: 'CAD', name: 'Canadian Dollar' },
  { code: 'AUD', name: 'Australian Dollar' },
] as const;

const CreatePaymentFormDrawer: React.FC<ICreatePaymentFormDrawer> = ({
  open,
  setOpen,
  form,
  initialValues,
  eventStartDate,
}) => {
  const { t } = useTranslation();
  const { action } = fetchApi();

  const [loading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState<IFormField | null>(null);
  const [previewData, setPreviewData] = useState<PaymentFormData | null>(null);
  const [currency, setCurrency] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [fieldCollections, setFieldCollections] = useState<IFieldCollection[]>(
    initialValues?.fieldCollections || []
  );
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
  const [registrationStart, setRegistrationStart] = useState<Moment>(
    initialValues?.payStartDate ? moment(initialValues.payStartDate) : moment()
  );

  const [registrationEnd, setRegistrationEnd] = useState<Moment>(
    initialValues?.payEndDate
      ? moment(initialValues.payEndDate)
      : moment().add(1, 'hour')
  );
  const [refundDeadLine, setRefundDeadLine] = useState<Moment | null>(
    initialValues?.refundDeadLine ? moment(initialValues.refundDeadLine) : null
  );

  const [timeZone, setTimeZone] = useState<string>(
    initialValues?.payTimezone
      ? momentTimezone.tz(initialValues.payTimezone).format('Z')
      : format(new Date(), 'xxx')
  );
  const [showStripeNavModal, setShowStripeNavModal] = useState<boolean>(false);
  const eventId = initialValues?._id;
  const paymentForm = initialValues?.forms?.find(
    (el) => el?.type === 'payment'
  );
  const formId = paymentForm?.id;

  useEffect(() => {
    changeRefundDate();
  }, [registrationStart]);

  const changeRefundDate = useCallback(() => {
    // Automatically update refund deadline to 15 days before the start date
    const estimatedRefundDate = moment(eventStartDate).subtract(15, 'days');
    const _refundDate = estimatedRefundDate.isAfter(moment().add(1, 'day'))
      ? estimatedRefundDate
      : moment().add(1, 'day');
    const refundDate = moment(_refundDate).utcOffset(
      mapTimezoneOffsetToMinutes(timeZone),
      true
    );
    setRefundDeadLine(refundDate);
  }, [eventStartDate, timeZone]);

  const closeDrawer = () => {
    setIsOnboardingOpen(false);
    setPreviewData(null);
    setOpen(false);
  };

  const showPaymentForm = () => {
    setIsOnboardingOpen(false);
    setOpen(true);
    setPreviewData(null);
  };

  const handleAddCollection = (collectionEnum: PaymentCollection) => {
    console.log('PF: handleAddCollection:', collectionEnum);

    const collection = getCollectionByEnum(collectionEnum);
    if (collection.fields.length === 1 && collection.mustEditBeforeSubmit) {
      setFieldToEdit(collection.fields[0]);
      setIsModalOpen(true);
    } else {
      setFieldCollections((prev) => [...prev, collection]);
    }
  };

  const handleAddCustomField = (type: FormFieldType) => {
    const baseFieldConfig = {
      type,
      name: '',
      label: '',
      required: false,
    };

    const specificFieldConfigs = {
      [FormFieldType.MultipleOptions]: {
        ...baseFieldConfig,
        options: [],
      },
    };

    setIsModalOpen(true);
    setFieldToEdit(specificFieldConfigs[type] || baseFieldConfig);
  };

  const handleRemoveCollection = (collectionIndex: number) => {
    setFieldCollections((prevCollections) =>
      prevCollections.filter((_, index) => index !== collectionIndex)
    );
  };

  const handleRemoveField = (collectionIndex: number, fieldIndex: number) => {
    setFieldCollections((prevCollections) => {
      const newCollections = [...prevCollections];
      newCollections[collectionIndex].fields.splice(fieldIndex, 1);
      if (newCollections[collectionIndex].fields.length === 0) {
        newCollections.splice(collectionIndex, 1);
      }
      return newCollections;
    });
  };

  const handleEditField = (collectionIndex: number, fieldIndex: number) => {
    const field = fieldCollections[collectionIndex].fields[fieldIndex];
    setFieldToEdit(field);
    setIsModalOpen(true);
  };

  const updateFieldInCollections = (
    prevCollections: IFieldCollection[],
    fieldToEdit: IFormField | null,
    updatedField: IFormField
  ): IFieldCollection[] => {
    const updatedCollections = [...prevCollections];

    // Check if editing an existing field
    if (fieldToEdit) {
      // Try to find in existing collections first
      const collectionIndex = updatedCollections.findIndex((collection) =>
        collection.fields.includes(fieldToEdit)
      );

      if (collectionIndex !== -1) {
        const collectionToEdit = updatedCollections[collectionIndex];
        const fieldIndex = collectionToEdit.fields.indexOf(fieldToEdit);
        updatedCollections[collectionIndex].fields[fieldIndex] = updatedField;
        return updatedCollections;
      }

      // If not found in existing collections, check predefined collections
      const predefinedCollection = Object.values(PaymentCollection).find(
        (collectionEnum) => {
          const collection = getCollectionByEnum(collectionEnum);
          return collection?.fields.some((f) => f.name === fieldToEdit.name);
        }
      );

      if (predefinedCollection) {
        const collection = getCollectionByEnum(predefinedCollection);
        if (collection) {
          const fieldIndex = collection.fields.findIndex(
            (f) => f.name === fieldToEdit.name
          );
          if (fieldIndex !== -1) {
            collection.fields[fieldIndex] = updatedField;
            updatedCollections.push(collection);
            return updatedCollections;
          }
        }
      }
    }

    // If no field to edit, add a new collection
    return [...updatedCollections, { fields: [updatedField] }];
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFieldToEdit(null);
  };

  const handleModalSubmit = (field: IFormField) => {
    setFieldCollections((prevCollections) =>
      updateFieldInCollections(prevCollections, fieldToEdit, field)
    );
    handleCloseModal();
  };

  const addedCollections = fieldCollections.map(({ name }) => name);
  const availableCollections = Object.values(PaymentCollection).filter(
    (collectionEnum) => !addedCollections.includes(collectionEnum)
  );

  const getTimezone = useMemo(() => {
    const minimalTimeOne = minimalTimezoneSet.filter(
      (d) => d?.offset === timeZone
    );
    if (!minimalTimeOne.length) return '';

    const { offset, tzCode, label } = minimalTimeOne[0];
    const tzAbbrMoment = moment.tz(tzCode).format('z');
    const isAbbrValid = !tzAbbrMoment.match(/[+-]/);

    return isAbbrValid ? `(GMT${offset}) ${tzAbbrMoment}` : label;
  }, [timeZone]);

  const updateTimezone = (offset: string) => {
    const updatedStartMoment = moment(registrationStart).utcOffset(
      mapTimezoneOffsetToMinutes(offset)
    );
    const updatedEndMoment = moment(registrationEnd).utcOffset(
      mapTimezoneOffsetToMinutes(offset)
    );
    const updatedRefundDeadLine = moment(refundDeadLine).utcOffset(
      mapTimezoneOffsetToMinutes(offset)
    );

    setRegistrationStart(updatedStartMoment);
    setRegistrationEnd(updatedEndMoment);
    setRefundDeadLine(updatedRefundDeadLine);
    form.change('payStartDate', updatedStartMoment.valueOf());
    form.change('payEndDate', updatedEndMoment.valueOf());
    form.change('payTimezone', offset);
  };

  const isPaymentFormValid = () => {
    const errors = form.getState().errors;

    if (errors.currency) {
      form.mutators.setFieldTouched('currency');
      return false;
    }

    if (fieldCollections.length === 0) {
      return false;
    }
    return true;
  };

  const handleContinue = () => {
    if (!isPaymentFormValid()) return;

    const formData: PaymentFormData = {
      description: form.getState().values.description,
      metadata: { currency: form.getState().values.currency },
      openingDate: registrationStart.valueOf(),
      closingDate: registrationEnd.valueOf(),
      refundDeadLine: refundDeadLine ? refundDeadLine.valueOf() : null,
      timeZone: timeZone,
      fieldCollections: fieldCollections,
      type: 'payment',
    };
    if (formId) {
      formData.id = formId;
    }
    setShowStripeNavModal(true);
    setPreviewData(formData);
    setIsOnboardingOpen(true);
  };

  const handleSave = (values) => {
    if (values) {
      form.change('paymentForm', values);
      setIsOnboardingOpen(false);
      setOpen(false);
      setPreviewData(null);
    }
  };

  useEffect(() => {
    if (initialValues) {
      if (initialValues.payStartDate) {
        setRegistrationStart(moment(initialValues.payStartDate));
        form.change('payStartDate', initialValues.payStartDate);
      }
      if (initialValues.payEndDate) {
        setRegistrationEnd(moment(initialValues.payEndDate));
        form.change('payEndDate', initialValues.payEndDate);
      }
      if (initialValues.payTimezone) {
        setTimeZone(initialValues.payTimezone);
        form.change('payTimezone', initialValues.payTimezone);
      }
      if (initialValues.refundDeadLine) {
        setRefundDeadLine(moment(initialValues.refundDeadLine));
        form.change('refundDeadLine', initialValues.refundDeadLine);
      }
    }
  }, [initialValues]);

  useEffect(() => {
    if (registrationStart.isAfter(registrationEnd)) {
      const newEndDate = moment(registrationStart).add(1, 'hour');
      setRegistrationEnd(newEndDate);
      form.change('payEndDate', newEndDate.valueOf());
    }
  }, [registrationStart, registrationEnd, form]);

  useEffect(() => {
    const fetchFormData = async () => {
      {
        setIsLoading(true);
        try {
          const response = await action(API_NAME.GET_EVENT_FORMS, {
            params: { eventId, formId },
          });
          if (response?.data) {
            setRegistrationEnd(moment(response?.data?.closingDate));
            setRegistrationStart(moment(response?.data?.openingDate));
            setFieldCollections(response?.data?.fieldCollections);
            setCurrency(response?.data?.metadata?.currency);
            setDescription(response?.data?.description);
            setRefundDeadLine(moment(response?.data?.refundDeadLine));
          }
        } catch (error) {
          console.error('Failed to fetch form data:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    if (open && eventId && formId) {
      fetchFormData();
    }
  }, [open, eventId, formId, action]);

  return (
    <Drawer
      open={open}
      onDrawerClose={closeDrawer}
      headerText={t('event:paymentForm')}
      onIconClick={isOnboardingOpen ? showPaymentForm : closeDrawer}
      submitText={isOnboardingOpen ? '' : t('event:continue')}
      onSubmit={isOnboardingOpen ? () => {} : handleContinue}
    >
      {loading ? (
        <LoadingComponent
          fullWidth
          loaderSize={50}
          color="#C2C2C2"
          variant="spin"
        />
      ) : previewData ? (
        <AccountSelectionDrawer
          onBack={showPaymentForm}
          onSaveValues={handleSave}
          paymentFormData={previewData}
          group={form.getState().values?.group}
        />
      ) : (
        <>
          <SectionWrapper pt={1}>
            <InputDescriptionField
              label={t('event:descriptionOptional')}
              name="description"
              initialValue={description}
              showCharactersNumber
              maxLength={3000}
              height={100}
            />
          </SectionWrapper>

          <SectionWrapper pt={16.5} pb={16.5}>
            <SectionHeader>{t('event:paymentDates')}</SectionHeader>

            <Field name="payStartDate">
              {({ input }) => (
                <ScheduleTime
                  selectedDate={getDateInOriginalTimezone(registrationStart)}
                  headerText={t('timePicker:start')}
                  onScheduleTimeChange={(value) => {
                    const updatedMoment = moment(value).utcOffset(
                      mapTimezoneOffsetToMinutes(timeZone),
                      true
                    );
                    setRegistrationStart(updatedMoment);
                    input.onChange(updatedMoment.valueOf());
                  }}
                  minDate={new Date()}
                />
              )}
            </Field>

            <Field name="payEndDate">
              {({ input }) => (
                <ScheduleTime
                  selectedDate={getDateInOriginalTimezone(registrationEnd)}
                  headerText={t('timePicker:end')}
                  onScheduleTimeChange={(value) => {
                    const updatedMoment = moment(value).utcOffset(
                      mapTimezoneOffsetToMinutes(timeZone),
                      true
                    );
                    setRegistrationEnd(updatedMoment);
                    input.onChange(updatedMoment.valueOf());
                  }}
                  minDate={registrationStart.toDate() || new Date()}
                />
              )}
            </Field>

            <Field name="refundDeadLine">
              {({ input }) => (
                <ScheduleTime
                  selectedDate={
                    refundDeadLine
                      ? getDateInOriginalTimezone(refundDeadLine)
                      : null
                  }
                  headerText={t('timePicker:refundDeadline')}
                  onScheduleTimeChange={(value) => {
                    const updatedMoment = moment(value).utcOffset(
                      mapTimezoneOffsetToMinutes(timeZone),
                      true
                    );
                    setRefundDeadLine(updatedMoment);
                    input.onChange(updatedMoment.valueOf());
                  }}
                  minDate={new Date()}
                />
              )}
            </Field>

            <SeparateSettingsHeader mb={4} mt={16}>
              {t('timePicker:timezone')}
            </SeparateSettingsHeader>

            <Field name="payTimezone">
              {() => {
                form.change('payTimezone', timeZone);
                return (
                  <SelectWrapper>
                    <SelectIconWrap>
                      <WorldIcon />
                    </SelectIconWrap>
                    <TimeZoneText>{getTimezone}</TimeZoneText>
                    <Select
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setTimeZone(e.target.value);
                        updateTimezone(e.target.value);
                      }}
                      value={timeZone}
                    >
                      {minimalTimezoneSet.map((tz, index) => (
                        <option value={tz.offset} key={index}>
                          {tz.label}
                        </option>
                      ))}
                    </Select>
                  </SelectWrapper>
                );
              }}
            </Field>
          </SectionWrapper>

          <SectionWrapper pt={1}>
            <Label>{t('event:selectCurrency')}</Label>
            <Field
              name="currency"
              validate={required('This field is required!')}
              initialValue={currency}
            >
              {({ input, meta }) => (
                <>
                  <CurrencySelector
                    placeholder={t('event:selectCurrency')}
                    value={input.value}
                    onChange={(value) => {
                      input.onChange(value);
                      setCurrency(value);
                      form.change('currency', value);
                    }}
                    options={CURRENCIES?.map((option) => ({
                      value: option.code,
                      label: `${option.code}`,
                    }))}
                    variant="borderless"
                    style={{
                      width: '100%',
                      height: 44,
                      outline: 'none',
                      fontFamily: 'Poppins',
                      fontWeight: 400,
                      borderRadius: 4,
                      background: '#f7f7f7',
                      border: '1px solid #ff950a',
                    }}
                  />
                  {meta.touched && meta.error && (
                    <ValidationErrorWrapper
                      style={{ color: 'red', marginTop: '5px' }}
                    >
                      {meta.error}
                    </ValidationErrorWrapper>
                  )}
                </>
              )}
            </Field>
          </SectionWrapper>

          <SectionWrapper>
            <SectionHeader>{t('event:selectTemplates')}</SectionHeader>
            {availableCollections.map((collectionEnum) => (
              <Pill
                fontSize={12}
                margin="0 10px 8px 0"
                text={`+ ${t(`event:${collectionEnum}`)}`}
                onClick={() => handleAddCollection(collectionEnum)}
              />
            ))}
          </SectionWrapper>

          <SectionWrapper>
            <SectionHeader>{t('event:addQuestion')}</SectionHeader>
            {Object.values(FormFieldType).map(
              (type) =>
                (FormFieldTypeLabels[type] !== 'signature'&&FormFieldTypeLabels[type] !== 'phone') && (
                  <Pill
                    key={type}
                    fontSize={12}
                    margin="0 10px 8px 0"
                    text={t(`event:${FormFieldTypeLabels[type]}`)}
                    onClick={() => handleAddCustomField(type)}
                  />
                )
            )}
          </SectionWrapper>

          <FieldDetailsModal
            open={isModalOpen}
            field={fieldToEdit}
            onClose={handleCloseModal}
            onSubmit={handleModalSubmit}
            metadata={{ isPaymentField: true }}
          />

          <SectionWrapper>
            <>
              <SectionHeader>{t('event:questions')}</SectionHeader>
              {fieldCollections.map((collection, collectionIndex) => (
                <FieldCollection
                  key={`${collection.name}-${collectionIndex}`}
                  currency={currency}
                  collection={collection}
                  onRemoveCollection={() =>
                    handleRemoveCollection(collectionIndex)
                  }
                  onRemoveField={(fieldIndex) =>
                    handleRemoveField(collectionIndex, fieldIndex)
                  }
                  onEditField={(fieldIndex) =>
                    handleEditField(collectionIndex, fieldIndex)
                  }
                />
              ))}
              {fieldCollections.length === 0 && (
                <ValidationErrorWrapper
                  style={{
                    color: 'red',
                    marginTop: '5px',
                    fontSize: '15px',
                    textAlign: 'center',
                  }}
                >
                  {t('event:addFieldCollectionToContinue')}
                </ValidationErrorWrapper>
              )}
            </>
          </SectionWrapper>
        </>
      )}
      {showStripeNavModal && (
        <FormNotifyModal
          status={AttenderStatus.StripeOnboarding}
          onClose={() => setShowStripeNavModal(false)}
        />
      )}
    </Drawer>
  );
};

export default CreatePaymentFormDrawer;
