import React, { useEffect } from 'react';
import { Input, Button, Space } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { StyledCard, FieldsContainer, FieldRow } from './CreateWaiver.styles';
import { IFormField } from 'components/DynamicFormComponents/FormField/FormField';
import { useTranslation } from 'react-i18next';
import { getTranslatedLabel } from 'components/FormGenerator/utils';

interface WaiverFieldsProps {
  fields: IFormField[];
  setFields: (fields: IFormField[]) => void;
  setIsModalVisible: (visible: boolean) => void;
}

const WaiverFields: React.FC<WaiverFieldsProps> = ({
  fields,
  setFields,
  setIsModalVisible,
}) => {
  const { t } = useTranslation();

  return (
    <StyledCard title={t('common:waiverFields')}>
      <FieldsContainer>
        <Space direction="vertical" style={{ width: '100%' }}>
          {fields.map(({ name, label, required }) => (
            <FieldRow key={name}>
              <Input
                disabled={required}
                placeholder={getTranslatedLabel(label)}
                style={{ width: '100%' }}
              />
              {!required && (
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const updatedFields = fields.filter(
                      (field: IFormField) => field.name !== name
                    );
                    setFields(updatedFields);
                  }}
                />
              )}
            </FieldRow>
          ))}
        </Space>
      </FieldsContainer>

      <Button
        icon={<PlusOutlined />}
        onClick={() => setIsModalVisible(true)}
        style={{ width: '100%', marginTop: '16px' }}
      >
        {t('createWaiver:addNewField')}
      </Button>
    </StyledCard>
  );
};

export default WaiverFields;
