import React from 'react';
import {
  StatsContainer,
  LeftSection,
  IconWrapper,
  CountSection,
  Count,
  Label,
  Amount,
  VerticalLine,
  Text,
  RightSection,
} from './AttendenceStats.style';
import { FrendsIcon, ExternalLink } from 'public/assets/icons';
import { useTranslation } from 'react-i18next';

interface AttendanceStatsProps {
  confirmedCount: number;
  totalAmount: number | string;
  handleSendReport?: () => void;
}

const AttendanceStats: React.FC<AttendanceStatsProps> = ({
  confirmedCount,
  totalAmount,
  handleSendReport,
}) => {
  const { t } = useTranslation();
  return (
    <StatsContainer onClick={handleSendReport}>
      <LeftSection>
        <IconWrapper>
          <FrendsIcon colorStroke="white" />
        </IconWrapper>
        <CountSection>
          <Count>{confirmedCount}</Count>
          <Label>{t('event:confirmed')}</Label>
        </CountSection>
        <VerticalLine />
        {totalAmount &&  <Amount>{totalAmount}</Amount>}
      </LeftSection>
      <RightSection>
        <IconWrapper>
          <ExternalLink />
        </IconWrapper>
        <Text>{t('event:sendReport')}</Text>
      </RightSection>
    </StatsContainer>
  );
};

export default AttendanceStats;
