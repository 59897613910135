import styled from 'styled-components';

export const TemplateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;
export const TemplateInfoContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
export const ThreeButtonContainer = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto!important;
  overflow: visible;
`;
export const TemplateName = styled.span`
font-size:12px;
color:'black
`;
export const EmptyListText =styled.span`
font-size:12px;
color:'black;
`;
