import React, { useEffect, useRef, useState } from 'react';
import { MESSAGE_TYPE } from 'utils/constants';
import { setSearchParams, handleRedirect } from 'store/search/actions';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import _, { filter } from 'lodash';
import { clearSidebarStore } from 'store/sideBarControler/actions';
import { getUserByName } from 'services';
import { routesPath } from 'utils/constants/routePath';
import { ILeftMessageItem } from './ILeftMessageItem';
import {
  MessageContainer,
  UserProfilePhoto,
  MessageWrap,
  MessageTextWrapper,
  MessageHeaderText,
  TimeText,
  UserProfilePhotoWrap,
  ThreeDotsWrap,
  PopOverWrap,
  PinIconContainer,
} from './LeftMessageItem.style';
import { EmptyUserProfilePhoto, MessageText } from '../../index';
import { ThreeDotsIcon } from 'public/assets/icons';
import { ListUserUserActions } from '../../Popover/Popover.style';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';

export const LeftMessageItem = ({
  messageType,
  profilePhoto,
  username,
  message,
  datetime,
  onMessageOwnerPress,
  onDeleteMessageClick,
  isPinned = false,
  onPinMessageClick,
  isAdmin,
}: ILeftMessageItem) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const messageRef = useRef(null);
  const { t } = useTranslation();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopupAbove, setIsPopupAbove] = useState(false);

  // Function to check if the message is at the bottom of the list
  const checkIfAtBottom = () => {
    if (messageRef.current) {
      const bottom = messageRef.current.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;
      const distanceFromBottom = windowHeight - bottom;

      // If the message is near the bottom (or below the viewport), open the popup above
      setIsPopupAbove(distanceFromBottom < 100); // Adjust the value as needed
    }
  };

  // Check position whenever the component mounts or the popover is toggled
  useEffect(() => {
    checkIfAtBottom();
  }, [isPopoverOpen]);

  const onHashtagClick = (item) => {
    const postText = _.trim(item).substring(1);
    dispatch(handleRedirect(false));
    dispatch(
      setSearchParams({
        category: 2,
        start: 0,
        limit: 20,
        q: postText,
        paramsForFilter: 0,
      })
    );

    router.push(routesPath.searchPath);
  };

  const onMentionClick = async (item) => {
    const mentionUsername = _.trim(item).substring(1);

    const userInfo = await getUserByName(mentionUsername);

    if (userInfo.status === 200) {
      if (userInfo?.data?.type === 'user')
        router.push(`${routesPath.user_details}/${userInfo.data._id}`);
      if (userInfo?.data?.type === 'group') {
        router.push(`${routesPath.group_details}/${userInfo.data._id}`);
      }
      if (userInfo?.data?.type === 'event') {
        router.push(`${routesPath.event_details}/${userInfo.data._id}`);
      }
    }
    dispatch(clearSidebarStore());
  };

  return (
    <>
      <MessageContainer
        ref={messageRef}
        onMouseLeave={() => setIsPopoverOpen(false)}
      >
        <UserProfilePhotoWrap>
          {messageType === MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_ITEM ||
          messageType === MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_NO_TITLE_ITEM ? (
            <UserProfilePhoto role="img" aria-label="user profile img" />
          ) : profilePhoto && profilePhoto.trim() ? (
            <UserProfilePhoto
              img={profilePhoto}
              onClick={onMessageOwnerPress}
              cursor="pointer"
              role="img"
              aria-label="user profile img"
            />
          ) : (
            <EmptyUserProfilePhoto
              wrapSize={32}
              userIconSize={16}
              onClick={onMessageOwnerPress}
              cursor="pointer"
              withBorder={false}
            />
          )}
        </UserProfilePhotoWrap>

        <MessageWrap
          isPinned={isPinned}
          isFullRounded={
            !(
              messageType !== MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_ITEM &&
              messageType !== MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_NO_TITLE_ITEM
            )
          }
        >
          {isPinned && (
            <PinIconContainer>
              <Image
                src={'/assets/img/push-pin.png'}
                width={15}
                height={15}
                objectFit="contain"
              />
            </PinIconContainer>
          )}
          {messageType !== MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_NO_TITLE_ITEM &&
            messageType !==
              MESSAGE_TYPE.MESSAGE_LEFT_COMPLETED_NO_TITLE_ITEM && (
              <MessageHeaderText mb={4} onClick={onMessageOwnerPress}>
                {username}
              </MessageHeaderText>
            )}
          <MessageTextWrapper>
            <MessageText
              text={message || ''}
              onHashtagClick={onHashtagClick}
              onMentionClick={onMentionClick}
            />
          </MessageTextWrapper>
        </MessageWrap>
        {isAdmin&&<ThreeDotsWrap
          className="chat-message-three-dots"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <ThreeDotsIcon colorStroke="#C2C2C2" />
        </ThreeDotsWrap>}

        {isPopoverOpen && (
          <PopOverWrap
            width={140}
            top={isPopupAbove ? '-50px' : '30px'} // Conditionally adjust top position
            left={messageRef.current?.offsetWidth > 250 ? 70 : -135}
          >
            <ListUserUserActions>
              {isAdmin && (
                <li onClick={onDeleteMessageClick}>{t('common:delete')}</li>
              )}
              {isAdmin && (
                <li onClick={onPinMessageClick}>
                  {isPinned ? t('common:unpinMessage') : t('common:pinMessage')}
                </li>
              )}
            </ListUserUserActions>
          </PopOverWrap>
        )}
      </MessageContainer>
      {messageType !== MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_ITEM &&
        messageType !== MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_NO_TITLE_ITEM && (
          <TimeText>{datetime}</TimeText>
        )}
    </>
  );
};
