import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SidebarWrapper, InputField } from 'components';
import {
  closeSidebarPlanRoute,
  openSidebarChoseWayPoint,
  openSidebarChooseRouteMethod,
  openSidebarWhereTo,
  createRouteStart,
  updateRouteStart,
} from 'store/map/actions';
import { ArrowBack, LocationIcon } from 'public/assets/icons';
import { AddWayPointIcon } from 'pages/map/icons';
import {
  FINISH_WAYPOINT_TYPE,
  MapRoutePointTypes,
  START_WAYPOINT_TYPE,
} from 'utils/constants';
import { getMinutesAndHoursFromSeconds } from 'utils/transforms';
import { convertUnitsOfMeasurement } from 'utils/convertUnitsOfMeasurement';
import { reAssignTypeToWaypoints } from 'utils/mapUtils';
import {
  ContentContainer,
  WaypointsHeader,
  AddWayPointWrap,
  AddImportRouteWrap,
} from './SidebarPlanRoute.style';
import { ISidebarPlanRoute } from './ISidebarPlanRoute';
import {
  MapSidebarWrap,
  EndPoint,
  StartPoint,
  WayPoint,
  RouteSummary,
  VerticalDashedLine,
} from '../index';
import { style } from 'utils/constants/style';
import { AsyncStatus } from 'utils/types';
import { useRouter } from 'next/router';
import { routesPath } from 'utils/constants/routePath';

const SidebarPlanRoute = ({
  waypoints,
  routePoints,
  onDeleteWaypoint,
  setWaypoints,
  routeName,
  setRouteName,
}: ISidebarPlanRoute) => {
  const router = useRouter();

  const {
    directionsForDriving,
    routeForEditData,
    updateRouteReqStat,
    createRouteReqStat,
  } = useSelector((state) => state.map);

  const [routeTime, setRouteTime] = useState({
    minutes: '',
    hours: '',
  });
  const [routeDistance, setRouteDistance] = useState('');
  const [dragIndex, setDragIndex] = useState(0);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    if (directionsForDriving) {
      const { distance, duration } = directionsForDriving;
      let distanceResult;

      if (settings?.units) {
        distanceResult = Math.round(distance / 1000);
      } else {
        distanceResult = convertUnitsOfMeasurement(distance / 1000, false);
      }

      const { hours, minutes } = getMinutesAndHoursFromSeconds(duration);

      setRouteTime({ hours, minutes });
      setRouteDistance(distanceResult);
    }
  }, [directionsForDriving, settings]);

  useEffect(() => {
    if (routeForEditData && !waypoints.length) {
      setWaypoints(routeForEditData.waypoints);
    }
  }, []);

  const onBackIconClick = () => {
    batch(() => {
      dispatch(closeSidebarPlanRoute());
      if (!routeForEditData) {
        dispatch(openSidebarWhereTo());
      }
    });
  };

  const onRemoveWayPointClick = (pointIndex) => {
    onDeleteWaypoint({
      pointType: MapRoutePointTypes.WAY,
      pointIndex: pointIndex + 1,
    });
  };

  const onSubmit = () => {
    if (routeForEditData) {
      dispatch(
        updateRouteStart({
          routeData: waypoints,
          routeName,
          routeId: routeForEditData.routeId,
        })
      );
    } else {
      dispatch(
        createRouteStart({
          routeData: waypoints,
          routePoints,
          routeName,
          isPublic: true,
        })
      );
    }
  };

  const onDrag = (index) => {
    setDragIndex(index);
  };

  const onDrop = (ev) => {
    if (waypoints.length >= 2) {
      const dragWaypoint = waypoints[dragIndex];
      const dropWaypointIndex = Number(ev.currentTarget.id);
      const dropWaypoint = waypoints[dropWaypointIndex];

      let newWaypoints = [...waypoints];

      newWaypoints[dragIndex] = dropWaypoint;
      newWaypoints[dropWaypointIndex] = dragWaypoint;

      newWaypoints = reAssignTypeToWaypoints(newWaypoints);

      setWaypoints(newWaypoints);
    }
  };

  const isSubmitAvailable = () => {
    let isEditRouteSubmit = true;

    if (routeForEditData) {
      const dataNotChange =
        JSON.stringify(routeForEditData.waypoints) ===
          JSON.stringify(waypoints) && routeForEditData.routeName === routeName;

      if (dataNotChange) {
        isEditRouteSubmit = false;
      }
    }

    const isStartPointSelected = waypoints.find(
      (item) => item.type === START_WAYPOINT_TYPE
    );

    return Boolean(
      isStartPointSelected &&
        waypoints.length >= 2 &&
        routeName &&
        isEditRouteSubmit
    );
  };

  const renderWaypoints = () => {
    const isStartPointExists = waypoints.find(
      (item) => item.type === START_WAYPOINT_TYPE
    );

    if (waypoints.length <= 1) {
      return (
        <EndPoint
          address={
            waypoints?.find(
              (_waypoint) => _waypoint?.type === FINISH_WAYPOINT_TYPE
            )?.waypointName
          }
          onCrossClick={() =>
            onDeleteWaypoint({
              pointType: MapRoutePointTypes.END,
              pointIndex: 0,
            })
          }
          onDrop={onDrop}
          waypointIndex={0}
          onDrag={(e) => onDrag(0)}
        />
      );
    }

    const waypointsForRender = isStartPointExists
      ? waypoints.slice(1)
      : [...waypoints];

    return waypointsForRender.map((item, index) => {
      if (index === waypointsForRender.length - 1) {
        return (
          <EndPoint
            address={item.waypointName}
            onCrossClick={() =>
              onDeleteWaypoint({
                pointType: MapRoutePointTypes.END,
                pointIndex: index + 1,
              })
            }
            onDrop={onDrop}
            waypointIndex={index + 1}
            onDrag={(e) => onDrag(index + 1)}
            key={`${item.placeId}_${index}`}
          />
        );
      }

      return (
        <div key={`${item.placeId}_${index}`}>
          <WayPoint
            address={item.waypointName}
            pointNumber={index + 1}
            onCloseClick={() => onRemoveWayPointClick(index)}
            onDrop={onDrop}
            waypointIndex={index + 1}
            onDrag={(e) => onDrag(index + 1)}
          />
          <VerticalDashedLine />
        </div>
      );
    });
  };

  return (
    <MapSidebarWrap>
      <SidebarWrapper
        headerText={t('map:planRoute')}
        headerIcon={<ArrowBack />}
        submitText={t('editUserProfile:save')}
        isSubmitAvailable={isSubmitAvailable()}
        onSubmit={onSubmit}
        onIconClick={onBackIconClick}
        contentPadding="20px"
        loading={
          updateRouteReqStat === AsyncStatus.LOADING ||
          createRouteReqStat === AsyncStatus.LOADING
        }
      >
        <InputField
          value={routeName}
          onChange={(e) => setRouteName(e.target.value)}
          label={t('map:routeName')}
          crossIcon
          onCrossClick={() => setRouteName('')}
        />
        <ContentContainer mt={17} pb={16}>
          <WaypointsHeader mb={18}>{t('map:waypoints')}</WaypointsHeader>
          <StartPoint
            address={
              waypoints.find((item) => item.type === START_WAYPOINT_TYPE)
                ?.waypointName
            }
            onCloseClick={() =>
              onDeleteWaypoint({
                pointType: MapRoutePointTypes.START,
              })
            }
            onDrop={onDrop}
            waypointIndex={0}
            onDrag={(e) => onDrag(0)}
          />
          <VerticalDashedLine />
          {renderWaypoints()}
          <AddWayPointWrap
            onClick={() => {
              batch(() => {
                dispatch(closeSidebarPlanRoute());
                dispatch(openSidebarChoseWayPoint());
              });
            }}
          >
            <AddWayPointIcon />
            <p>{t('map:addWaypoint')}</p>
          </AddWayPointWrap>
          <AddImportRouteWrap
            onClick={() => {
              batch(() => {
                dispatch(closeSidebarPlanRoute());
                dispatch(openSidebarChooseRouteMethod());
              });
            }}
          >
            <LocationIcon color={style.mainYellow.yellow} />
            <p>{t('map:importRoutes')}</p>
          </AddImportRouteWrap>
        </ContentContainer>

        {waypoints.find((item) => item.type === START_WAYPOINT_TYPE) &&
          waypoints.length >= 2 && (
            <ContentContainer mt={16} pb={28}>
              <WaypointsHeader mb={18}>{t('map:routeSummary')}</WaypointsHeader>
              <RouteSummary
                distance={routeDistance}
                hours={routeTime.hours}
                minutes={routeTime.minutes}
                units={settings?.units ? 'km' : 'mi'}
              />
            </ContentContainer>
          )}
      </SidebarWrapper>
    </MapSidebarWrap>
  );
};

export default SidebarPlanRoute;
