import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAccountSelectionDrawer } from './IAccountSelectionDrawer';
import API_NAME from 'services/api';
import {
  AttendanceViewButton,
  SectionWrapper,
} from './AccountSelectionDrawer.styles';
import { LoadingComponent } from 'components';

import Button from 'components/common/Button';
import { useStripeConnect } from 'components/StripeConnectOnboarding/useStripeConnect';
import StripeConnectOnboarding from 'components/StripeConnectOnboarding/StripeConnectOnboarding';
import PaymentFormPreview from 'containers/CreatePaymentFormDrawer/PaymentFormPreview';
import moment from 'moment';
import fetchApi from 'hooks/fetchApi';
import { OnboardingFailure } from './OnboardingFailure';

interface GetEventStripeAccountResponse {
  data?: {
    isVerified: boolean;
    missingRequirements: string[];
  };
}

const AccountSelectionDrawer: React.FC<IAccountSelectionDrawer> = ({
  group,
  onBack,
  onSaveValues,
  paymentFormData,
  isClubPayment,
}) => {
  const { t } = useTranslation();
  const { action } = fetchApi();
  const [showFormPreview, setShowFormPreview] = useState(false);
  const [isAccountOnBoarded, setIsAccountOnBoarded] = useState(false);
  const [showOnBoardingError, setShowOnBoardingError] = useState(false);
  const [missingRequirements, setMissingRequirements] = useState<string[]>([]);
  const {
    isLoading,
    stripeAccountId,
    stripeConnectInstance,
    initializeStripeConnect,
  } = useStripeConnect(group);

  const handleFinishPreviewClick = () => {
    setShowFormPreview(true);
  };

  const handleGoBackClick = () => {
    setShowFormPreview(false);
  };

  useEffect(() => {
    const initialize = async () => {
      await initializeStripeConnect();
    };
    initialize();
  }, []);

  const handleSavePaymentForm = () => {
    onSaveValues(paymentFormData);
  };

  const handleOnBoardingExit = async () => {
    const response = (await action(API_NAME.GET_EVENT_STRIPE_ACCOUNT, {
      params: { stripeAccountId },
    })) as GetEventStripeAccountResponse;

    if (response?.data && response.data?.isVerified) {
      setIsAccountOnBoarded(true);
    } else if (response.data?.missingRequirements?.length) {
      setShowOnBoardingError(true);
      setMissingRequirements(response.data.missingRequirements);
    }
  };

  const handleReinitializeStripe = async () => {
    await initializeStripeConnect();
    setShowOnBoardingError(false);
  };

  return (
    <>
      {isLoading ? (
        <LoadingComponent
          fullWidth
          loaderSize={50}
          color="#C2C2C2"
          variant="spin"
        />
      ) : showFormPreview ? (
        <PaymentFormPreview
          description={paymentFormData.description}
          startDate={moment(paymentFormData.openingDate)}
          endDate={moment(paymentFormData.closingDate)}
          timeZone={paymentFormData.timeZone}
          fieldCollections={paymentFormData.fieldCollections}
          currency={paymentFormData?.metadata?.currency}
          refundDeadLine={moment(paymentFormData.refundDeadLine)}
        />
      ) : (
        <>
          <SectionWrapper pt={1}>
            {stripeConnectInstance !== null && !showOnBoardingError && (
              <StripeConnectOnboarding
                onExit={handleOnBoardingExit}
                connectInstance={stripeConnectInstance}
              />
            )}
            {showOnBoardingError && (
              <OnboardingFailure
                missingRequirements={missingRequirements}
                handleRetryOnboarding={handleReinitializeStripe}
              />
            )}
          </SectionWrapper>
        </>
      )}

      <AttendanceViewButton>
        <Button
          width="full"
          variant="primary"
          onClick={showFormPreview ? () => handleGoBackClick() : () => onBack()}
        >
          {t('event:goBack')}
        </Button>
        <Button
          width="full"
          variant="primary"
          disabled={!isAccountOnBoarded}
          onClick={
            isClubPayment || showFormPreview
              ? handleSavePaymentForm
              : handleFinishPreviewClick
          }
        >
          {isClubPayment || showFormPreview
            ? t('map:finish')
            : t('event:Finish&Preview')}
        </Button>
      </AttendanceViewButton>
    </>
  );
};

export default AccountSelectionDrawer;
