export enum AUTH_FORMS_VARIANT {
  SIGN_IN,
  SIGN_UP,
  FORGET_PASSWORD,
  EMAIL_VERIFY,
  ACCOUNT_RECOVER,
}
export enum APP_STATE {
  EXPAND_IMAGES_TOGGLE,
  POST_SENSOR_ID,
  PAGE_LOADING,
  CREATE_PHOTO_POST_FORM,
  EDIT_PHOTO_POST_FORM,
  CREATE_LINK_POST_FORM,
  EVENT_CREATE_FORM,
  CREATE_DISCUSSION_FORM,
  EDIT_DISCUSSION_FORM,
  EVENT_EDIT_FORM,
  LIKES_DRAWER_ACTION,
  BUSINESS_CONTACT_FORM,
  ADD_VEHICLE_FORM,
  ADD_VEHICLE_DRAWER,
  ADD_VEHICLE_EVENT_DRAWER,
  EDIT_VEHICLE_FORM,
  EDIT_PROFILE,
  CREATE_GROUP_DRAWER,
  MAP_GET_BACK_FROM_ROUTE,
}

export enum SEARCH_TABS {
  PROFILE = 'profiles',
  GROUP = 'groups',
  POSTS = 'posts',
  VEHICLE = 'vehicles',
  EVENT = 'events',
  DISCUSSIONS = 'discussions',
}

export enum SEARCH_SUB_TABS {
  ALL = 'all',
  CALENDAR = 'calendar',
  NEARBY = 'nearby',
  BRANDS = 'brands',
  PHOTOS = 'photos',
  LINKS = 'links',
  VIDEOS = 'videos',
  MODELS = 'models',
  DISCUSSIONS = 'discussions',
}

export enum SEARCH_FILTER_TYPES {
  PROFILE_ALL,
  PROFILE_NEARBY,
  PROFILE_BRANDS,
  GROUPS_ALL,
  GROUPS_NEARBY,
  POSTS_ALL,
  POSTS_NEARBY,
  POSTS_PHOTOS,
  POSTS_LINKS,
  POSTS_VIDEOS,
  VEHICLES_ALL,
  VEHICLES_NEARBY,
  VEHICLES_BRANDS,
  VEHICLES_MODELS,
  EVENTS_ALL,
  EVENTS_CALENDAR,
  EVENTS_NEARBY,
}

export enum SEARCH_FEED_TABS {
  FOLLOWING = 'following',
  DISCOVER = 'discover',
  EVENTS = 'events',
  SHOP = 'shop',
  BUSSINESS = 'business',
  ROUTES = 'routes'
}

export enum FEED_SUB_TABS {
  ALL = 'all',
  CALENDAR = 'calendar',
  NEARBY = 'nearby',
  FOLLOWING = 'following',
  MY_CLUBS = 'myClubs',
  LOCATION = 'location',
  TYPE = 'type',
  POPULAR = 'popular',
}

export enum AttenderStatus {
  Removed = 'REMOVED',
  Rejected = 'REJECTED',
  Attending = 'ATTENDING',
  Waitlisted = 'WAITLISTED',
  PendingPayment = 'PENDING_PAYMENT',
  ReadyToConfirm = 'READY_TO_CONFIRM',
  PendingPaymentForm = 'PENDING_PAYMENT_FORM',
  PendingHostApproval = 'PENDING_HOST_APPROVAL',
  PendingRegistrationForm = 'PENDING_REGISTRATION_FORM',
  PendingRegistration = 'PENDING_REGISTRATION',
  PendingApproval = 'PENDING_APPROVAL',
  PendingWaiver = 'PENDING_WAIVER',
  StripeOnboarding ='stripe-onboarding'
}
