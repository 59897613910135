
import i18next from 'i18next';

export const convertDateToString = (date: any) => {
  if (!date) return '';
  return date.map((d: any) => d.format('YYYY-MM-DD')).join(' - ');
};

export const getTranslatedLabel = (label: string, monetaryValue?: number | string) => {
  const translatedLabel =
    i18next.t(`event:${label}`) === `event:${label}`
      ? label
      : i18next.t(`event:${label}`);

  return monetaryValue
    ? `${translatedLabel} ($${+monetaryValue / 100})`
    : translatedLabel;
};