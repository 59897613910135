/* eslint-disable no-case-declarations */
import { HYDRATE } from 'next-redux-wrapper';

import { AsyncStatus } from 'utils/types';
import { actionTypes } from './actions';

const initialState = {
  createPostError: null,
  parseLinkError: null,
  createEventError: null,
  linkInfo: null,
  eventDetails: {},
  postDetails: null,
  groupDetails: {},
  deletedEventInfo: {
    isEventDeleted: false,
    eventId: null,
  },
  deletedPostInfo: {
    isPostDeleted: false,
    postId: null,
  },

  editedPostInfo: null,

  popularPosts: null,

  refreshEventPosts: null,
  refreshGroupPosts: null,

  isScheduledPostCreated: false,

  attendingEvents: {
    canceled: [],
    attended: [],
  },

  groupFollowers: null,
  groupFollowersTotal: 0,
  groupMembers: null,
  groupMembersTotal: 0,
  groupAdmins: null,
  eventInvited: null,
  eventAttending: null,
  eventWaitListing: null,
  likesPost: null,
  likesVehicle: null,
  likesEvent: null,

  feedFollowingPostsData: {
    feedFollowingPosts: [],
    previousFollowingPostIds: [],
    likeIds: [],
  },
  feedDiscoverPostsData: {
    feedDiscoverPosts: [],
    previousDiscoverPostIds: [],
    likeIds: [],
  },
  feedEventPosts: [],
  feedParams: {
    isRedirect: false,
    changeFeedType: 0,
    myFeedLimit: 0,
    discoverLimit: 0,
    eventsLimit: 0,
  },

  groupPosts: [],
  pinnedPosts: [],
  groupPostsLikeIds: [],
  groupVehicles: [],
  redirectFromGroupDetailsData: {
    isRedirectFromMyGroups: false,
    scrollYPosition: 0,
  },

  eventPosts: [],
  eventPostsLikeIds: [],
  eventVehicles: [],
  redirectFromEventDetailsData: {
    isRedirectFromEventDetails: false,
    scrollYPosition: 0,
  },
  createEventData: null,

  createPostReqStat: AsyncStatus.NONE,
  parseLinkReqStat: AsyncStatus.NONE,
  createEventReqStat: AsyncStatus.NONE,
  getEventDetailsReqStat: AsyncStatus.NONE,
  getPostDetailsReqStat: AsyncStatus.NONE,
  reportPostReqStat: AsyncStatus.NONE,
  reportGroupReqStat: AsyncStatus.NONE,
  reportEventReqStat: AsyncStatus.NONE,
  editGroupReqStat: AsyncStatus.NONE,
  getGroupDetailsReqStat: AsyncStatus.NONE,
  editEventReqStat: AsyncStatus.NONE,
  cancelEventReqStat: AsyncStatus.NONE,
  deleteEventReqStat: AsyncStatus.NONE,
  editPostReqStat: AsyncStatus.NONE,
  deletePostReqStat: AsyncStatus.NONE,
  getPopularPostsReqStat: AsyncStatus.NONE,
  attendEventWithVehicleReqStat: AsyncStatus.NONE,
  attendEventWIthoutVehicleReqStat: AsyncStatus.NONE,
  cancelEventSubscriptionReqStat: AsyncStatus.NONE,
  getGroupFollowersReqStat: AsyncStatus.NONE,
  getGroupMembersReqStat: AsyncStatus.NONE,
  deleteGroupFollowerReqStat: AsyncStatus.NONE,
  deleteGroupMemberReqStat: AsyncStatus.NONE,
  getEventInvitedReqStat: AsyncStatus.NONE,
  getEventAttendingReqStat: AsyncStatus.NONE,
  getEventWaitListingReqStat: AsyncStatus.NONE,
  getLikesPostReqStat: AsyncStatus.NONE,
  getLikesVehicleReqStat: AsyncStatus.NONE,
  getLikesEventReqStat: AsyncStatus.NONE,
  getGroupAdminsV12ReqStat: AsyncStatus.NONE,
  getFeedFollowingPostsReqStat: AsyncStatus.NONE,
  getFeedDiscoverPostsReqStat: AsyncStatus.NONE,
  getFeedEventPostsReqStat: AsyncStatus.NONE,
  getGroupPostsReqStat: AsyncStatus.NONE,
  getGroupVehiclesReqStat: AsyncStatus.NONE,
  getEventPostsReqStat: AsyncStatus.NONE,
  getEventVehiclesReqStat: AsyncStatus.NONE,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    // ADD POST
    case actionTypes.CREATE_POST_START:
      return { ...state, createPostReqStat: AsyncStatus.LOADING };

    case actionTypes.CREATE_POST_SUCCESS:
      return { ...state, createPostReqStat: AsyncStatus.SUCCESS };

    case actionTypes.CREATE_POST_ERROR:
      return {
        ...state,
        createPostReqStat: AsyncStatus.ERROR,
        createPostError: action.error,
      };

    case actionTypes.CLEAR_CREATE_POST_REQ_STAT:
      return {
        ...state,
        createPostReqStat: AsyncStatus.NONE,
        createPostError: null,
      };

    // PARSE LINK
    case actionTypes.PARSE_LINK_START:
      return { ...state, parseLinkReqStat: AsyncStatus.LOADING };

    case actionTypes.PARSE_LINK_SUCCESS:
      return {
        ...state,
        parseLinkReqStat: AsyncStatus.SUCCESS,
        linkInfo: action.data,
        parseLinkError: null,
      };

    case actionTypes.PARSE_LINK_ERROR:
      return {
        ...state,
        parseLinkReqStat: AsyncStatus.ERROR,
        linkInfo: null,
        parseLinkError: `${String(state.parseLinkError)}1`,
      };

    // CLEAR POST STORE
    case actionTypes.CLEAR_POSTS_STORE:
      return { ...initialState };

    // CREATE EVENT
    case actionTypes.CREATE_EVENT_START:
      return { ...state, createEventReqStat: AsyncStatus.LOADING };

    case actionTypes.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        createEventData: action.data,
        createEventReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.CREATE_EVENT_ERROR:
      return {
        ...state,
        createEventReqStat: AsyncStatus.ERROR,
        createEventError: action.error,
      };

    case actionTypes.CLEAR_CREATE_EVENT_REQ_STAT:
      return {
        ...state,
        createEventReqStat: AsyncStatus.NONE,
        createEventError: null,
      };

    // GET EVENT INFO
    case actionTypes.GET_EVENT_DETAILS_START:
      return { ...state, getEventDetailsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        eventDetails: action.data,
        getEventDetailsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_EVENT_DETAILS_ERROR:
      return { ...state, getEventDetailsReqStat: AsyncStatus.ERROR };

    // GET POST DETAILS
    case actionTypes.GET_POST_DETAILS_START:
      return { ...state, getPostDetailsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_POST_DETAILS_SUCCESS:
      return {
        ...state,
        postDetails: action.data,
        getPostDetailsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_POST_DETAILS_ERROR:
      return { ...state, getPostDetailsReqStat: AsyncStatus.ERROR };

    case actionTypes.CLEAR_POST_DETAILS_DATA:
      return {
        ...state,
        postDetails: null,
        getPostDetailsReqStat: AsyncStatus.NONE,
      };

    // FAVOURITE POST
    case actionTypes.PUT_FAVOURITE_MEDIA_REQUEST:
      return { ...state };

    case actionTypes.PUT_FAVOURITE_MEDIA_SUCCESS:
      return { ...state };

    case actionTypes.PUT_FAVOURITE_MEDIA_ERROR:
      return { ...state };

    case actionTypes.DELETE_FAVOURITE_MEDIA_REQUEST:
      return { ...state };

    case actionTypes.DELETE_FAVOURITE_MEDIA_SUCCESS:
      return { ...state };

    case actionTypes.DELETE_FAVOURITE_MEDIA_ERROR:
      return { ...state };

    // REPORT POST
    case actionTypes.REPORT_POST_START:
      return { ...state, reportPostReqStat: AsyncStatus.LOADING };

    case actionTypes.REPORT_POST_SUCCESS:
      return { ...state, reportPostReqStat: AsyncStatus.SUCCESS };

    case actionTypes.REPORT_POST_ERROR:
      return { ...state, reportPostReqStat: AsyncStatus.ERROR };

    // REPORT GROUP
    case actionTypes.REPORT_GROUP_START:
      return { ...state, reportGroupReqStat: AsyncStatus.LOADING };

    case actionTypes.REPORT_GROUP_SUCCESS:
      return { ...state, reportGroupReqStat: AsyncStatus.SUCCESS };

    case actionTypes.REPORT_GROUP_ERROR:
      return { ...state, reportGroupReqStat: AsyncStatus.ERROR };

    // REPORT EVENT
    case actionTypes.REPORT_EVENT_START:
      return { ...state, reportEventReqStat: AsyncStatus.LOADING };

    case actionTypes.REPORT_EVENT_SUCCESS:
      return { ...state, reportEventReqStat: AsyncStatus.SUCCESS };

    case actionTypes.REPORT_EVENT_ERROR:
      return { ...state, reportEventReqStat: AsyncStatus.ERROR };

    // EDIT GROUP
    case actionTypes.EDIT_GROUP_START:
      return { ...state, editGroupReqStat: AsyncStatus.LOADING };

    case actionTypes.EDIT_GROUP_SUCCESS:
      return { ...state, editGroupReqStat: AsyncStatus.SUCCESS };

    case actionTypes.EDIT_GROUP_ERROR:
      return { ...state, editGroupReqStat: AsyncStatus.ERROR };

    case actionTypes.CLEAR_EDIT_GROUP_STATUS:
      return { ...state, editGroupReqStat: AsyncStatus.NONE };

    // GET GROUP DETAILS
    case actionTypes.GET_GROUP_DETAILS_START:
      return { ...state, getGroupDetailsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        getGroupDetailsReqStat: AsyncStatus.SUCCESS,
        groupDetails: action.data,
      };

    case actionTypes.GET_GROUP_DETAILS_ERROR:
      return { ...state, getGroupDetailsReqStat: AsyncStatus.ERROR };

    // EDIT EVENT
    case actionTypes.EDIT_EVENT_START:
      return { ...state, editEventReqStat: AsyncStatus.LOADING };

    case actionTypes.EDIT_EVENT_SUCCESS:
      return { ...state, editEventReqStat: AsyncStatus.SUCCESS };

    case actionTypes.EDIT_EVENT_ERROR:
      return { ...state, editEventReqStat: AsyncStatus.ERROR };

    case actionTypes.CLEAR_EDIT_EVENT_REQ_STAT:
      return { ...state, editEventReqStat: AsyncStatus.NONE };

    // CANCEL EVENT
    case actionTypes.CANCEL_EVENT_START:
      return { ...state, cancelEventReqStat: AsyncStatus.LOADING };

    case actionTypes.CANCEL_EVENT_SUCCESS:
      return { ...state, cancelEventReqStat: AsyncStatus.SUCCESS };

    case actionTypes.CANCEL_EVENT_ERROR:
      return { ...state, cancelEventReqStat: AsyncStatus.ERROR };

    // DELETE EVENT
    case actionTypes.DELETE_EVENT_START:
      return { ...state, deleteEventReqStat: AsyncStatus.LOADING };

    case actionTypes.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        deletedEventInfo: action.data,
        deleteEventReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.DELETE_EVENT_ERROR:
      return { ...state, deleteEventReqStat: AsyncStatus.ERROR };

    // EDIT POST
    case actionTypes.EDIT_POST_START:
      return {
        ...state,
        editPostReqStat: AsyncStatus.LOADING,
      };

    case actionTypes.EDIT_POST_SUCCESS:
      return {
        ...state,
        editedPostInfo: action.data,
        editPostReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.EDIT_POST_ERROR:
      return { ...state, editPostReqStat: AsyncStatus.ERROR };

    case actionTypes.CLEAR_EDIT_POST_STATUS:
      return {
        ...state,
        editedPostInfo: null,
        editPostReqStat: AsyncStatus.NONE,
      };

    // DELETE POST
    case actionTypes.DELETE_POST_START:
      return { ...state, deletePostReqStat: AsyncStatus.LOADING };

    case actionTypes.DELETE_POST_SUCCESS:
      return {
        ...state,
        deletedPostInfo: action.data,
        deletePostReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.DELETE_POST_ERROR:
      return { ...state, deletePostReqStat: AsyncStatus.ERROR };

    // REFRESH EVENT POSTS
    case actionTypes.REFRESH_EVENT_POSTS:
      return { ...state, refreshEventPosts: true };

    // REFRESH GROUP POSTS
    case actionTypes.REFRESH_GROUP_POSTS:
      return { ...state, refreshGroupPosts: true };

    // REFRESH FEED POSTS
    case actionTypes.REFRESH_FEED_POSTS:
      return {
        ...state,
        feedFollowingPostsData: {
          ...state.feedFollowingPostsData,
          feedFollowingPosts: [
            action.data,
            ...state.feedFollowingPostsData.feedFollowingPosts,
          ],
        },
      };

    // CLEAR REFRESH STATUS
    case actionTypes.CLEAR_REFRESHING_STATUS:
      return { ...state, refreshEventPosts: null, refreshGroupPosts: null };

    // GET POPULAR POSTS
    case actionTypes.GET_POPULAR_POSTS_START:
      return { ...state, getPopularPostsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_POPULAR_POSTS_SUCCESS:
      return {
        ...state,
        popularPosts: action.data,
        getPopularPostsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_POPULAR_POSTS_ERROR:
      return { ...state, getPopularPostsReqStat: AsyncStatus.ERROR };

    // IS SCHEDULED POST CREATED
    case actionTypes.SET_IS_SCHEDULED_POST_CREATED:
      return { ...state, isScheduledPostCreated: action.data };

    // ATTEND EVENT WITH VEHICLE
    case actionTypes.ATTEND_EVENT_WITH_VEHICLE_START:
      return { ...state, attendEventWithVehicleReqStat: AsyncStatus.LOADING };

    case actionTypes.ATTEND_EVENT_WITH_VEHICLE_SUCCESS: {
      const { canceled, attended } = state.attendingEvents;

      return {
        ...state,
        attendingEvents: {
          attended: [...attended, action.data],
          canceled: canceled.filter((el) => el !== action.data),
        },
        attendEventWithVehicleReqStat: AsyncStatus.SUCCESS,
      };
    }

    case actionTypes.ATTEND_EVENT_WITH_VEHICLE_ERROR:
      return { ...state, attendEventWithVehicleReqStat: AsyncStatus.ERROR };

    // ATTEND EVENT WITHOUT VEHICLE
    case actionTypes.ATTEND_EVENT_WITHOUT_VEHICLE_START:
      return {
        ...state,
        attendEventWithoutVehicleReqStat: AsyncStatus.LOADING,
      };

    case actionTypes.ATTEND_EVENT_WITHOUT_VEHICLE_SUCCESS: {
      const { canceled, attended } = state.attendingEvents;

      return {
        ...state,
        attendingEvents: {
          attended: [...attended, action.data],
          canceled: canceled.filter((el) => el !== action.data),
        },
        attendEventWithoutVehicleReqStat: AsyncStatus.SUCCESS,
      };
    }

    case actionTypes.ATTEND_EVENT_WITHOUT_VEHICLE_ERROR:
      return { ...state, attendEventWithoutVehicleReqStat: AsyncStatus.ERROR };

    // CANCEL EVENT SUBSCRIPTION
    case actionTypes.CANCEL_EVENT_SUBSCRIPTION_START:
      return { ...state, cancelEventSubscriptionReqStat: AsyncStatus.LOADING };

    case actionTypes.CANCEL_EVENT_SUBSCRIPTION_SUCCESS: {
      const { canceled, attended } = state.attendingEvents;

      return {
        ...state,
        attendingEvents: {
          attended: attended.filter((el) => el !== action.data),
          canceled: [...canceled, action.data],
        },
        cancelEventSubscriptionReqStat: AsyncStatus.SUCCESS,
      };
    }

    case actionTypes.CANCEL_EVENT_SUBSCRIPTION_ERROR:
      return { ...state, cancelEventSubscriptionReqStat: AsyncStatus.ERROR };

    // GET GROUP FOLLOWERS
    case actionTypes.GET_GROUP_FOLLOWERS_START:
      return { ...state, getGroupFollowersReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_GROUP_FOLLOWERS_SUCCESS:
      return {
        ...state,
        groupFollowers: action.data?.data,
        groupFollowersTotal: action.data?.total,
        getGroupFollowersReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_GROUP_FOLLOWERS_ERROR:
      return { ...state, getGroupFollowersReqStat: AsyncStatus.ERROR };

    // GET GROUP MEMBERS
    case actionTypes.GET_GROUP_MEMBERS_START:
      return { ...state, getGroupMembersReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_GROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        groupMembers: action.data?.data,
        groupMembersTotal: action.data?.total,
        getGroupMembersReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_GROUP_MEMBERS_ERROR:
      return { ...state, getGroupMembersReqStat: AsyncStatus.ERROR };

    // GET GROUP ADMINS V12
    case actionTypes.GET_GROUP_ADMINS_V12_START:
      return { ...state, getGroupAdminsV12ReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_GROUP_ADMINS_V12_SUCCESS:
      return {
        ...state,
        groupAdmins: action.data,
        getGroupAdminsV12ReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_GROUP_ADMINS_V12_ERROR:
      return { ...state, getGroupAdminsV12ReqStat: AsyncStatus.ERROR };

    // DELETE GROUP FOLLOWER
    case actionTypes.DELETE_GROUP_FOLLOWER_START:
      return { ...state, deleteGroupFollowerReqStat: AsyncStatus.LOADING };

    case actionTypes.DELETE_GROUP_FOLLOWER_SUCCESS:
      return { ...state, deleteGroupFollowerReqStat: AsyncStatus.SUCCESS };

    case actionTypes.DELETE_GROUP_FOLLOWER_ERROR:
      return { ...state, deleteGroupFollowerReqStat: AsyncStatus.ERROR };

    // DELETE GROUP MEMBER
    case actionTypes.DELETE_GROUP_MEMBER_START:
      return { ...state, deleteGroupMemberReqStat: AsyncStatus.LOADING };

    case actionTypes.DELETE_GROUP_MEMBER_SUCCESS:
      return { ...state, deleteGroupMemberReqStat: AsyncStatus.SUCCESS };

    case actionTypes.DELETE_GROUP_MEMBER_ERROR:
      return { ...state, deleteGroupMemberReqStat: AsyncStatus.ERROR };

    // GET EVENT INVITED
    case actionTypes.GET_EVENT_INVITED_START:
      return { ...state, getEventInvitedReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_EVENT_INVITED_SUCCESS:
      return {
        ...state,
        eventInvited: action.data,
        getEventInvitedReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_EVENT_INVITED_ERROR:
      return { ...state, getEventInvitedReqStat: AsyncStatus.ERROR };

    // GET EVENT ATTENDING
    case actionTypes.GET_EVENT_ATTENDING_START:
      return { ...state, getEventAttendingReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_EVENT_ATTENDING_SUCCESS:
      return {
        ...state,
        eventAttending: action.data,
        getEventAttendingReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_EVENT_ATTENDING_ERROR:
      return { ...state, getEventAttendingReqStat: AsyncStatus.ERROR };

    // GET EVENT WAITLISTING
    case actionTypes.GET_EVENT_WAITLISTING_START:
      return { ...state, getEventWaitListingReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_EVENT_WAITLISTING_SUCCESS:
      return {
        ...state,
        eventWaitListing: action.data,
        getEventWaitListingReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_EVENT_WAITLISTING_ERROR:
      return { ...state, getEventWaitListingReqStat: AsyncStatus.ERROR };

    // GET LIKES POST
    case actionTypes.GET_LIKES_POST_START:
      return { ...state, getLikesPostReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_LIKES_POST_SUCCESS:
      return {
        ...state,
        likesPost: action.data,
        getLikesPostReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_LIKES_POST_ERROR:
      return { ...state, getLikesPostReqStat: AsyncStatus.ERROR };

    // GET LIKES VEHICLE
    case actionTypes.GET_LIKES_VEHICLE_START:
      return { ...state, getLikesVehicleReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_LIKES_VEHICLE_SUCCESS:
      return {
        ...state,
        likesVehicle: action.data,
        getLikesVehicleReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_LIKES_VEHICLE_ERROR:
      return { ...state, getLikesVehicleReqStat: AsyncStatus.ERROR };

    // GET LIKES EVENT
    case actionTypes.GET_LIKES_EVENT_START:
      return { ...state, getLikesEventReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_LIKES_EVENT_SUCCESS:
      return {
        ...state,
        likesEvent: action.data,
        getLikesEventReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_LIKES_EVENT_ERROR:
      return { ...state, getLikesEventReqStat: AsyncStatus.ERROR };

    // CLEAR LIKES STORE
    case actionTypes.CLEAR_LIKES_STORE:
      return {
        ...state,
        likesPost: null,
        likesEvent: null,
        likesVehicle: null,
        getLikesEventReqStat: AsyncStatus.NONE,
        getLikesVehicleReqStat: AsyncStatus.NONE,
        getLikesPostReqStat: AsyncStatus.NONE,
      };

    // CLEAR INVOLVED USERS POST STORE
    case actionTypes.CLEAR_INVOLVED_USERS_POST_STORE:
      return { ...state, [action.data]: null };

    // GET FEED FOLLOWING POSTS
    case actionTypes.GET_FEED_FOLLOWING_POSTS_START:
      return { ...state, getFeedFollowingPostsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_FEED_FOLLOWING_POSTS_SUCCESS:
      return {
        ...state,
        feedFollowingPostsData: {
          feedFollowingPosts: [
            ...state.feedFollowingPostsData.feedFollowingPosts,
            ...action.data,
          ],
          previousFollowingPostIds: action.data
            .filter((el) => el._id !== 'undefined' && el._id)
            .map((el) => el._id)
            .join(),
          likeIds: [
            ...state.feedFollowingPostsData.likeIds,
            ...action.data.filter((el) => el.isLike).map((el) => el._id),
          ],
        },
        getFeedFollowingPostsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_FEED_FOLLOWING_POSTS_ERROR:
      return { ...state, getFeedFollowingPostsReqStat: AsyncStatus.ERROR };

    case actionTypes.SET_NEW_FEED_FOLLOWING_POSTS_DATA:
      const isEdit = action.data.edit;

      return {
        ...state,
        feedFollowingPostsData: isEdit
          ? {
              feedFollowingPosts:
                state.feedFollowingPostsData.feedFollowingPosts.map((el) => {
                  if (el._id === action.data.postId) {
                    return {
                      ...el,
                      ...action.data.postData,
                      ...action.data.tagsFullInfo,
                    };
                  }

                  return el;
                }),
            }
          : {
              feedFollowingPosts:
                state.feedFollowingPostsData.feedFollowingPosts.filter(
                  (el) => el._id !== action.data.postId
                ),
            },
      };

    // GET FEED DISCOVER POSTS
    case actionTypes.GET_FEED_DISCOVER_POSTS_START:
      return { ...state, getFeedDiscoverPostsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_FEED_DISCOVER_POSTS_SUCCESS:
      return {
        ...state,
        feedDiscoverPostsData: {
          feedDiscoverPosts: [
            ...state.feedDiscoverPostsData.feedDiscoverPosts,
            ...action.data,
          ],
          previousDiscoverPostIds: action.data
            .filter((el) => el._id !== 'undefined' && el._id)
            .map((el) => el._id)
            .join(),
          likeIds: [
            ...state.feedDiscoverPostsData.likeIds,
            ...action.data.filter((el) => el.isLike).map((el) => el._id),
          ],
        },
        getFeedDiscoverPostsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_FEED_DISCOVER_POSTS_ERROR:
      return { ...state, getFeedDiscoverPostsReqStat: AsyncStatus.ERROR };

    // GET FEED EVENT POSTS
    case actionTypes.GET_FEED_EVENT_POSTS_START:
      return { ...state, getFeedEventPostsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_FEED_EVENT_POSTS_SUCCESS:
      return {
        ...state,
        feedEventPosts: [...state.feedEventPosts, ...action.data],
        getFeedEventPostsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_FEED_EVENT_POSTS_ERROR:
      return { ...state, getFeedEventPostsReqStat: AsyncStatus.ERROR };

    // SET FEED PARAMS
    case actionTypes.SET_FEED_PARAMS:
      return { ...state, feedParams: { ...state.feedParams, ...action.data } };

    // SET LIKE
    case actionTypes.SET_LIKE:
      const { postId, isFollowingFeed, isAdd } = action.data;

      const getNewLikesArray = (id, isAddLike, likesArray) => {
        if (isAddLike) {
          return [...likesArray, id];
        }

        return likesArray.filter((el) => el !== id);
      };

      if (isFollowingFeed) {
        return {
          ...state,
          feedFollowingPostsData: {
            ...state.feedFollowingPostsData,
            likeIds: getNewLikesArray(
              postId,
              isAdd,
              state.feedFollowingPostsData.likeIds
            ),
          },
        };
      }

      return {
        ...state,
        feedDiscoverPostsData: {
          ...state.feedDiscoverPostsData,
          likeIds: getNewLikesArray(
            postId,
            isAdd,
            state.feedDiscoverPostsData.likeIds
          ),
        },
      };

    // GROUP POSTS
    case actionTypes.GET_GROUP_POSTS_START:
      return { ...state, getGroupPostsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_GROUP_POSTS_SUCCESS:
      const defaultPosts = action.data?.filter((item) => !item.pin);
      const pinnedPosts = action.data?.filter((item) => item.pin);
      return {
        ...state,
        groupPosts: [...pinnedPosts]?.concat(defaultPosts),
        pinnedPosts: pinnedPosts,
        groupPostsLikeIds: action.data
          .filter((el) => el.isLike)
          .map((el) => el._id),
        getGroupPostsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_GROUP_POSTS_ERROR:
      return { ...state, getGroupPostsReqStat: AsyncStatus.ERROR };

    // SET REDIRECT FROM MY GROUPS
    case actionTypes.SET_REDIRECT_FROM_GROUP_DETAILS:
      return { ...state, redirectFromGroupDetailsData: action.data };

    // GET GROUP VEHICLES
    case actionTypes.GET_GROUP_VEHICLES_START:
      return { ...state, getGroupVehiclesReqStat: AsyncStatus.NONE };

    case actionTypes.GET_GROUP_VEHICLES_SUCCESS:
      return {
        ...state,
        groupVehicles: action.data,
        getGroupVehiclesReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_GROUP_VEHICLES_ERROR:
      return { ...state, getGroupVehiclesReqStat: AsyncStatus.ERROR };

    // ADD/REMOVE LIKE TO DETAILS SCREEN POST
    case actionTypes.ADD_LIKE_TO_DETAILS_SCREEN_POST:
      return {
        ...state,
        [action.data.type]: [...state[action.data.type], action.data.id],
      };

    case actionTypes.REMOVE_LIKE_FROM_DETAILS_SCREEN_POST:
      return {
        ...state,
        [action.data.type]: state[action.data.type].filter(
          (el) => el !== action.data.id
        ),
      };

    // SET POSTS ON DETAILS PAGE
    case actionTypes.SET_POSTS_ON_DETAILS_PAGE:
      return {
        ...state,
        [action.data.type]: action.data.posts,
      };

    // GET EVENT POSTS
    case actionTypes.GET_EVENT_POSTS_START:
      return { ...state, getEventPostsReqStat: AsyncStatus.NONE };

    case actionTypes.GET_EVENT_POSTS_SUCCESS:
      return {
        ...state,
        eventPosts: action.data,
        eventPostsLikeIds: action.data
          .filter((el) => el.isLike)
          .map((el) => el._id),
        getEventPostsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_EVENT_POSTS_ERROR:
      return { ...state, getEventPostsReqStat: AsyncStatus.ERROR };

    // GET EVENT VEHICLES
    case actionTypes.GET_EVENT_VEHICLES_START:
      return { ...state, getEventVehiclesReqStat: AsyncStatus.NONE };

    case actionTypes.GET_EVENT_VEHICLES_SUCCESS:
      return {
        ...state,
        eventVehicles: action.data,
        getEventVehiclesReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_EVENT_VEHICLES_ERROR:
      return { ...state, getEventVehiclesReqStat: AsyncStatus.ERROR };

    // SET REDIRECT FROM EVENT DETAILS
    case actionTypes.SET_REDIRECT_FROM_EVENT_DETAILS:
      return {
        ...state,
        redirectFromEventDetailsData: action.data,
      };
    case actionTypes.PIN_GROUP_POST: {
      // Update the pin status of the post
      const updatedGroupPosts = state.groupPosts.map((item) => ({
        ...item,
        pin: item?._id === action.postId ? action.pin : item.pin,
      }));

      // Move the pinned post to the top
      const reorderedGroupPosts = updatedGroupPosts.sort((a, b) => {
        if (a.pin && !b.pin) return -1; // Pinned posts come first
        if (!a.pin && b.pin) return 1; // Non-pinned posts come later
        return 0; // Preserve the order for other posts
      });

      return {
        ...state,
        groupPosts: reorderedGroupPosts,
      };
    }

    case actionTypes.REMOVE_GROUP_POST_TAGS: {
      const updatedGroupPosts = state.groupPosts.map((item) => {
        // If `item.groups` exists and is not empty
        if (item.groups && item.groups.length > 0) {
          // If the first group's `_id` matches `groupId`, remove the entire `item`
          if (item.groups[0]._id === action.groupId) {
            return null; // Mark for removal
          }
          // Otherwise, slice the first group from the `groups` array
          return {
            ...item,
            groups: item.groups.slice(1),
          };
        }
        return item; // If no groups, return the item unchanged
      });

      return {
        ...state,
        groupPosts: updatedGroupPosts.filter((item) => item !== null), // Remove null entries
      };
    }

    case actionTypes.PINNED_POSTS: {
      const pinnedPosts = state.groupPosts?.filter((item) => item.pin);
      return {
        ...state,
        pinnedPosts: pinnedPosts,
      };
    }
    case actionTypes.CLEAR_GROUP_POSTS: {
      return {
        ...state,
        groupPosts: null,
      };
    }
    default:
      return state;
  }
};

export default reducer;
