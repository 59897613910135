import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Drawer from 'components/Drawer';
import { LoadingComponent } from 'components';
import { useTranslation } from 'react-i18next';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import {
  EmptyListText,
  ListContainer,
  TemplateContainer,
  TemplateInfoContainer,
  TemplateName,
  ThreeButtonContainer,
} from './ExistingClubWaiverDrawer.style';
import Image from 'next/image';
import { ThreeDotsIcon } from 'public/assets/icons';
import Dropdown from 'components/Dropdown';

const ExistingClubWaiverDrawer = ({
  open,
  setOpen,
  groupId,
  onCreate,
  onEdit,
}) => {
  const { t } = useTranslation();
  const { action } = fetchApi();
  const [loading, setLoading] = useState(false);
  const [waiverTemplates, setWaiverTemplates] = useState<string[]>([]);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState<number | null>(
    null
  );

  useEffect(() => {
    fetchWaiverTemplates();
  }, [action, groupId]);

  const fetchWaiverTemplates = async () => {
    setLoading(true);
    try {
      const response = await action(API_NAME.GET_WAIVER_TEMPLATES, {
        params: {
          id: groupId,
        },
      });

      if (response?.waiverTemplates) {
        setWaiverTemplates(response.waiverTemplates);
      }
    } catch (error) {
      console.error('Failed to fetch waiver templates:', error);
    } finally {
      setLoading(false);
    }
  };

  const getDropdownItems = useCallback(
    (item: any) => [
      {
        text: t('common:edit'),
        onClick: () => {
          onEdit(item, true);
        },
      },
      {
        text: t('common:delete'),
        onClick: () => {
          deleteWaiver(item?._id);
        },
      },
    ],
    [t]
  );

  const handleDropdownToggle = (index: number, isOpen: boolean) => {
    setActiveDropdownIndex(isOpen ? index : null);
  };

  const deleteWaiver = useCallback(async (templateId: string) => {
    setLoading(true);
    try {
      const response = await action(
        API_NAME.DELETE_CLUB_WAIVER,
        {
          params: {
            groupId,
            templateId,
          },
        },
        true
      );
      if (response) {
        setOpen(false);
        fetchWaiverTemplates();
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
      return;
    }
  }, []);

  return (
    <Drawer
      open={open}
      onDrawerClose={() => setOpen(false)}
      headerText={t('createWaiver:editExistingWaiver')}
      onIconClick={() => setOpen(false)}
      submitText={t('createWaiver:headerText')}
      onSubmit={onCreate}
    >
      {loading && (
        <LoadingComponent
          fullWidth
          loaderSize={50}
          color="#C2C2C2"
          variant="spin"
        />
      )}
      {!loading && waiverTemplates?.length <= 0 && (
        <EmptyListText>{t('event:noTemplatesAvailable')}</EmptyListText>
      )}
      {!loading &&
        waiverTemplates &&
        waiverTemplates?.map((item: any, index) => {
          const templateName = item?.url?.split('/').pop();
          let name = '';
          let date = '';

          if (templateName) {
            // Split the template name into parts to extract name and timestamp
            const parts = templateName.split('-');
            name = parts.slice(0, -1).join('-'); // Combine all but the last part for the name
            const timestamp = parts.slice(-1)[0]; // Extract the timestamp

            // Convert the timestamp to a readable date format
            date = new Date(parseInt(timestamp)).toLocaleDateString();
          }

          return (
            <ListContainer key={index}>
              <TemplateContainer>
                <TemplateInfoContainer>
                  <TemplateName>
                    {t('common:waiverTemplate')} {name} - {date}
                  </TemplateName>
                </TemplateInfoContainer>

                <Dropdown
                  top={-160}
                  right={1}
                  listItems={getDropdownItems(item)}
                  isOpen={activeDropdownIndex === index}
                  onToggle={(isOpen) => handleDropdownToggle(index, isOpen)}
                >
                  <ThreeButtonContainer>
                    <ThreeDotsIcon colorStroke="#C4C4C4" />
                  </ThreeButtonContainer>
                </Dropdown>
              </TemplateContainer>
            </ListContainer>
          );
        })}
    </Drawer>
  );
};

export default ExistingClubWaiverDrawer;
