import Text from 'components/common/Text';
import styled from 'styled-components';

interface IProps {
  variant?: string;
  disabled?: boolean;
  height?: number;
  t?:number;
  r?:number;
}

export const DropdownWrapper = styled.div`
  position: relative !important;
`;

export const DropdownButton = styled.button<IProps>`
  background: transparent;
  border: 1px solid transparent;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const CreateButton = styled.span`
  display: flex;
  align-items: center;

  & > span:last-child {
    margin-left: 8px;
  }
`;

export const ProfileButton = styled.span`
  display: flex;
  align-items: center;

  & > span:nth-child(2) {
    margin: 0 8px;
  }
`;

export const EditButton = styled.span``;
export const DotButton = styled.span``;

export const DropdownListContainer = styled.div<IProps>`
  position: absolute;
  top: ${(props) => (props.t ? `${props.t}px !important` : '-135px')};
  right: ${(props) => (props.r ? `${props.r}px !important` : '-20px')};
`;
export const SectionHeader = styled.div`
  padding: 8px 16px;
  font-weight: bold;
  margin-left: 12px;
`;

export const DropdownList = styled.ul<IProps>`
  width: ${(props) => (props.variant === 'right' ? '306px' : '210px')};
  position: relative;
  top: 190px;
  z-index: 110;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  padding: 0;
  flex-direction: column;
  list-style-type: none;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  height: ${(props) => props.height < 625 && `calc(${props.height}px - 100px)`};
  max-height: ${(props) => `calc(${props.height}px - 100px)`};
  /* overflow: ${(props) =>
    (props.variant === 'edit' || props.variant === 'dot') && 'hidden'}; */
  overflow-y: ${(props) => props.height < 625 ? 'scroll': 'auto'};

  -webkit-filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));

  /* &:hover {
    &::-webkit-scrollbar-thumb {
      display: block;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.neutral.n0};
  } */

  &::-webkit-scrollbar {
    display: none;
    /* width: 4px;
    background-color: ${(props) => props.theme.colors.neutral.n0}; */
  }
  /*
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.neutral.n6};
    border-radius: 10px;
    display: none;
  } */

  ${(props) => {
    if (props.variant) {
      if (props.variant === 'left') {
        return `
            &::after {
              content: '';
              position: absolute;
              height: 16px;
              width: 16px;
              background: ${props.theme.colors.white};
              top: -4px;
              left: 15px;
              transform: rotate(45deg);
              z-index: -1;
            }
        `;
      }
      if (props.variant === 'right') {
        return `
            &::after {
              content: '';
              position: absolute;
              height: 16px;
              width: 16px;
              background: ${props.theme.colors.white};
              top: -4px;
              right: 15px;
              transform: rotate(45deg);
              z-index: -1;
            }
      `;
      }
    }
  }}
`;

export const DropdownListItem = styled.li`
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSize.f5};
  color: ${(props) => props.theme.colors.text};
  line-height: 100%;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  padding: 14px 20px;
  transition: all 0.3s;
  cursor: pointer;
  /* border-radius: 10px; */
  /* overflow: hidden; */
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral.n3};
  border-top: 1px solid ${(props) => props.theme.colors.neutral.n3};
  margin-bottom: -1px;

  &:first-child,
  &:last-child {
    border-radius: 8px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.neutral.n3};
    /* border-radius: 10px !important; */
    transition: all 0.3s;
    &:first-child,
    &:last-child {
      border-radius: 8px;
    }
  }

  & > span {
    margin-right: 10px;
    display: flex;
    & > svg {
      & > path {
        /* fill: ${(props) => props.theme.colors.neutral.n8};  */
      }
    }
  }
`;

export const ItemsCount = styled.span`
  color: ${(props) => props.theme.colors.orange};
  display: inline-block;
  margin-left: auto;
`;

export const IconWrapper = styled.span`
  display: flex;
`;

export const TextWrapper = styled.span`
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  color: ${(props) => props.theme.colors.text};
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
`;

// export const ViewProfile = styled.div`
//   display: flex;
//   padding: 16px 16px 12px;
// `;

// export const ProfileImage = styled.div`
//   & > span {
//     border-radius: 50%;
//   }
// `;

// export const ViewProfileContent = styled.div`
//   margin-left: 12px;
// `;

// export const UserName = styled(Text)`
//   color: ${(props) => props.theme.colors.neutral.n12};
//   font-size: ${(props) => props.theme.typography.fontSize.f5};
//   letter-spacing: 0.5px;
// `;

// export const FullName = styled(Text)`
//   color: ${(props) => props.theme.colors.orange};
//   font-size: ${(props) => props.theme.typography.fontSize.f5};
//   letter-spacing: 0.5px;
//   margin: 2px 0 6px;
// `;
