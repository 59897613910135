import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import Drawer from 'components/Drawer';
import CreateEventPostForm from 'forms/CreateEventPostForm';
import { getCorrectImageBase64Format } from 'utils/getCorrectImageBase64Format';
import { routesPath } from 'utils/constants/routePath';
import appState from 'hooks/appState';
import { APP_STATE } from 'types';

import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';

import { ICreateEvent } from './ICreateEvent';
import { minimalTimezoneSet } from 'node_modules/compact-timezone-list';
import { useRef } from 'react';

const EVENT_TYPE = [
  {
    value: process.env._EVENT_MEETUPS,
    pinType: 'meetups',
  },
  {
    value: process.env._EVENT_DRIVES,
    pinType: 'drives',
  },
  {
    value: process.env._EVENT_RACES,
    pinType: 'races',
  },
  {
    value: process.env._EVENT_TRACK_DAYS,
    pinType: 'trackdays',
  },
  {
    value: process.env._EVENT_CARS_SHOWS,
    pinType: 'carshows',
  },
  {
    value: process.env._EVENT_OTHERS,
    pinType: 'otros',
  },
];
const initialValues = {
  chat: true,
  privacy: false,
  postPermission: false,
  broadcast: false,
  private: false,
};

const CreateEvent = ({
  open,
  variant = 'create',
  onSubmitComplete,
}: ICreateEvent) => {
  const { action, loading } = fetchApi();
  const appStateStore = useSelector((store) => store.app);
  const drawerContentRef = useRef();

  const router = useRouter();
  const appStateAction = appState();

  const onCreateEvent = async (v) => {
    try {
      const interestsValue = EVENT_TYPE.filter(
        (c) => c?.pinType === v?.interests
      )[0]?.value;

      const bodyData = {
        name: v?.eventName?.trim(),
        email: v?.contactEmail?.trim(),
        phone: v?.contactPhone?.trim(),
        website: v?.contactWebsite?.trim(),
        description: v?.eventDetails?.trim(),
        interests: [interestsValue],
        privacy: v?.privacy ? 1 : 0,
        postPermission: v?.postPermission ? 1 : 0,
        broadcast: !!v?.broadcast,
        private: !!v?.private,
        forms: [
          ...(v?.registrationForm ? [v?.registrationForm] : []),
          ...(v?.paymentForm ? [v?.paymentForm] : []),
          ...(v?.waiver ? [v?.waiver] : []),
        ],
        attendeeCap: v?.attendeeCap,
        isRealOwner: v?.isRealOwner,
        coverPhoto: getCorrectImageBase64Format(v?.coverPhoto),
        group: v?.group,
        chat: v?.chat,
        startDate: v?.startDate,
        endDate: v?.endDate,
        shareOnRoadStr:v?.shareOnRoadStr,
        timezone: minimalTimezoneSet.find((el) => el?.offset === v?.timezone)
          ?.tzCode,
        location: {
          address: v?.location?.address?.trim(),
          name: v?.location?.name?.trim(),
          lat: v?.location?.lat,
          lng: v?.location?.lng,
        },
        routeId: v?.routeId?._id,
        attenderManagement: v?.attenderManagement || 'auto',
        ...(v?.secretCode && {
          attenderManagementCode: v?.secretCode,
        }),
        ...(v?.brands && {
          brands: v?.brands,
        }),
        ...(v?.collaborators && {
          collaborators: v?.collaborators,
        }),
      };
      const res = await action(API_NAME.EVENT_CREATE, {
        body: bodyData,
      });
      if (res?.data?._id) {
        if (v?.vehicle[0]?.id) {
          await action(
            API_NAME.EVENT_ATTENDS_WITH_VEHICLE,
            {
              params: {
                eventId: res?.data?._id,
                vehicleId: v?.vehicle[0]?.id,
              },
            },
            true,
            false,
            [],
            false
          );
        } else {
          await action(API_NAME.EVENT_ATTENDS_WITHOUT_VEHICLE, {
            params: {
              eventId: res?.data?._id,
            },
          });
        }
        appStateAction(APP_STATE.EVENT_CREATE_FORM, {
          isOpen: false,
        });
        router.push(`${routesPath.event_details}/${res?.data?._id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer
      rootForm
      ref={drawerContentRef}
      open={
        variant === 'create'
          ? appStateStore[APP_STATE.EVENT_CREATE_FORM]?.isOpen
          : open
      }
      onDrawerClose={() => {
        if (variant === 'create') {
          appStateAction(APP_STATE.EVENT_CREATE_FORM, {
            isOpen: false,
          });
        } else {
          onSubmitComplete(null);
        }
      }}
    >
      <CreateEventPostForm
        onSubmit={onCreateEvent}
        variant={variant}
        initialValues={initialValues}
        drawerContentRef={drawerContentRef}
        onIconClick={() => {
          if (variant === 'create') {
            appStateAction(APP_STATE.EVENT_CREATE_FORM, {
              isOpen: false,
            });
          } else {
            onSubmitComplete(null);
          }
        }}
      />
    </Drawer>
  );
};

export default CreateEvent;
