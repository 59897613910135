import styled from 'styled-components';
import { Card, Upload } from 'antd';

export const StyledCard = styled(Card)`
  margin-bottom: 16px;

  .ant-card-body {
    padding: 16px;
  }
`;

export const StyledUpload = styled(Upload.Dragger)`
  .ant-upload-drag-icon {
    margin-bottom: 8px;
  }
`;

export const PreviewContainer = styled.div`
  margin-top: 16px;
`;

export const IframeContainer = styled.div`
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 8px;
  background-color: #f0f0f0;
`;

export const FieldsContainer = styled.div`
  margin-top: 16px;
`;

export const MiniPreviewContainer = styled.div`
  margin-top: 16px;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
  background-color: #f0f0f0;
`;

export const FileName = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  justify-content: center;
  width: 100%;
`;

export const FieldRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: #ff4d4f;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 16px;

  .anticon {
    margin-right: 8px;
    font-size: 20px; /* Increase the icon size */
  }
`;

export const OrSeparator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 16px 0;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #d9d9d9;
  }

  span {
    padding: 0 10px;
    color: #8c8c8c;
    font-size: 14px;
  }
`;

