import { useState } from 'react';
import {
  UserNameEvent,
  EventUserItemWrap,
  ImageWrap,
  EmptyEventImg,
  EventNameWrap,
} from '../AttendanceView.style';
import Image from 'components/common/Image';
import { EmptyGroupIcon } from 'public/assets/icons';
import { StatusDropdown } from './StatusDropdown';
import { ConfirmationModal } from 'components/Modals';
import { useTranslation } from 'react-i18next';

export const EventUserItem = ({
  el,
  loading,
  eventId,
  title,
  adminId,
  reloadData,
}: any) => {
  const { t } = useTranslation();
  const attendeeId = el && el?._id;
  const [showReminderModal, setShowReminderModal] = useState<boolean>(false);

  return (
    <EventUserItemWrap>
      {el?.profilePhoto ? (
        <ImageWrap>
          <Image
            loader={() => el.profilePhoto}
            src={el?.profilePhoto?.trim() || el?.coverPhoto?.trim()}
            alt="Picture of user"
            width={48}
            height={48}
            unoptimized
            priority
          />
        </ImageWrap>
      ) : (
        <ImageWrap>
          <EmptyEventImg>
            <EmptyGroupIcon />
          </EmptyEventImg>
        </ImageWrap>
      )}
      <EventNameWrap>
        <UserNameEvent>{el?.username}</UserNameEvent>
        <span>{el?.fullName}</span>
      </EventNameWrap>

      {attendeeId !== adminId && (
        <StatusDropdown
          eventId={eventId}
          attendeeId={el?._id}
          attendeeStatus={title}
          reloadData={reloadData}
          loading={loading}
          sendReminder={() => setShowReminderModal(true)}
        />
      )}
      {showReminderModal && (
        <ConfirmationModal
          headerText={t('common:reminder')}
          bodyText={t('common:reminderSent')}
          submitText={t('common:ok')}
          onlySubmitButton={true}
          onSubmit={() => setShowReminderModal(false)}
          onCancelClick={() => setShowReminderModal(false)}
        />
      )}
    </EventUserItemWrap>
  );
};
