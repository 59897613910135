import { useTranslation } from 'react-i18next';
import Drawer from 'components/Drawer';
import AccountSelectionDrawer from 'containers/AccountSelectionDrawer';
import { ClubPaymentFormDrawer } from './ClubPaymentFormDrawer';

const ClubPaymentDrawer: React.FC<ClubPaymentFormDrawer> = ({
  open,
  setOpen,
  groupId,
}) => {
  const { t } = useTranslation();

  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <Drawer
      open={open}
      onDrawerClose={closeDrawer}
      headerText={t('event:paymentForm')}
      onIconClick={closeDrawer}
    >
      <AccountSelectionDrawer
        onBack={closeDrawer}
        onSaveValues={closeDrawer}
        group={groupId}
        isClubPayment={true}
      />
    </Drawer>
  );
};

export default ClubPaymentDrawer;
