import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import { IScheduleTime } from './IScheduleTime';

import {
  ScheduleTimeWrapper,
  SeparateSettingsHeader,
} from './ScheduleTime.styles';

const ScheduleTime = ({
  headerText,
  selectedDate,
  onScheduleTimeChange,
  minDate,
}: IScheduleTime) => {
  return (
    <ScheduleTimeWrapper>
      <SeparateSettingsHeader type="h3" mb={6} mt={16}>
        {headerText}
      </SeparateSettingsHeader>
      <CustomDatePicker
        selectedDate={selectedDate || null}
        onChange={onScheduleTimeChange}
        minDate={minDate}
      />
    </ScheduleTimeWrapper>
  );
};

export default ScheduleTime;
