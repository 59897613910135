import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  isFullRounded?: boolean;
  mb?: number;
  width?: number;
  left?: number;
  isPinned?: boolean;
  top?:any
}

export const MessageContainer = styled.div<IProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-end;
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};

  :hover {
    .chat-message-three-dots {
      display: flex;
    }
  }
`;

export const MessageWrap = styled.div<IProps>`
  max-width: 84%;
  background-color: ${({ isPinned }) =>
    isPinned ? style.mainOrange.darkOrange : style.mainBlue.blue};
  padding: 10px;
  border-radius: ${({ isFullRounded }) =>
    isFullRounded ? '16px' : '16px 16px 0 16px'};
  font-size: 13px;
  line-height: 130%;
  font-weight: 300;
  color: ${style.mainWhite.white};
  margin-bottom: ${({ isFullRounded }) => (isFullRounded ? '0' : '6px')};
  position: relative;
`;

export const TimeWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const TimeText = styled.p`
  font-size: 12px;
  line-height: 100%;
  color: ${style.mainGray.SuvaGrey};
  margin-left: 10px;
  font-weight: 300;
`;

export const ThreeDotsWrap = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: ${style.mainGray.snowColor};
  cursor: pointer;
  position: absolute;
  top: 0;
  left: -41px;
`;

export const PopOverWrap = styled.div<IProps>`
  position: absolute;
  top: 30px;
  left: ${({ left }) => (left ? `${left}px` : 0)};
  z-index: 100;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  top: ${({ top }) => (top ? top : '30px')}; // Dynamically set top position
`;
export const PinIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
