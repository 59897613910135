/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import _ from 'lodash';

import { style } from 'utils/constants/style';
import { getUserByName } from 'services';
import { setSearchParams, handleRedirect } from 'store/search/actions';
import { routesPath } from 'utils/constants/routePath';
import { MESSAGE_TYPE } from 'utils/constants';
import { clearSidebarStore } from 'store/sideBarControler/actions';
import { PaperPlaneIcon, ThreeDotsIcon } from 'public/assets/icons';
import { IRightMessageItem } from './IRightMessageItem';
import { MessageText } from '../../index';
import {
  MessageContainer,
  MessageWrap,
  TimeWrap,
  TimeText,
  ThreeDotsWrap,
  PopOverWrap,
  PinIconContainer,
} from './RightMessageItem.style';
import { ListUserUserActions } from '../../Popover/Popover.style';
import Image from 'next/image';

export const RightMessageItem = ({
  messageType,
  message,
  isLoading,
  datetime,
  onDeleteMessageClick,
  isPinned,
  onPinMessageClick,
  isAdmin,
}: IRightMessageItem) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopupAbove, setIsPopupAbove] = useState(false);

  const dispatch = useDispatch();
  const router = useRouter();
  const messageRef = useRef(null);

  const { t } = useTranslation();

  // Function to check if the message is at the bottom of the list
  const checkIfAtBottom = () => {
    if (messageRef.current) {
      const bottom = messageRef.current.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;
      const distanceFromBottom = windowHeight - bottom;

      // If the message is near the bottom (or below the viewport), open the popup above
      setIsPopupAbove(distanceFromBottom < 100); // Adjust the value as needed
    }
  };

  // Check position whenever the component mounts or the popover is toggled
  useEffect(() => {
    checkIfAtBottom();
  }, [isPopoverOpen]);

  const onHashtagClick = (item) => {
    const postText = _.trim(item).substring(1);
    dispatch(handleRedirect(false));
    dispatch(
      setSearchParams({
        category: 2,
        start: 0,
        limit: 20,
        q: postText,
        paramsForFilter: 0,
      })
    );

    router.push(routesPath.searchPath);
  };

  const onMentionClick = async (item) => {
    const mentionUsername = _.trim(item).substring(1);

    const userInfo = await getUserByName(mentionUsername);

    if (userInfo.status === 200) {
      if (userInfo?.data?.type === 'user')
        router.push(`${routesPath.user_details}/${userInfo.data._id}`);
      if (userInfo?.data?.type === 'group') {
        router.push(`${routesPath.group_details}/${userInfo.data._id}`);
      }
      if (userInfo?.data?.type === 'event') {
        router.push(`${routesPath.event_details}/${userInfo.data._id}`);
      }
    }
    dispatch(clearSidebarStore());
  };

  return (
    <MessageContainer
      mb={
        messageType === MESSAGE_TYPE.MESSAGE_RIGHT_SIMPLE_NO_TITLE_ITEM ? 6 : 12
      }
      onMouseLeave={() => setIsPopoverOpen(false)}
    >
      <MessageWrap
        isFullRounded={
          messageType === MESSAGE_TYPE.MESSAGE_RIGHT_SIMPLE_NO_TITLE_ITEM
        }
        ref={messageRef}
        isPinned={isPinned}
      >
        {isPinned && (
          <PinIconContainer>
            <Image
              src={'/assets/img/push-pin.png'}
              width={15}
              height={15}
              objectFit="contain"
            />
          </PinIconContainer>
        )}
        <MessageText
          linkColor={style.mainWhite.white}
          text={message || ''}
          onHashtagClick={onHashtagClick}
          onMentionClick={onMentionClick}
        />

        <ThreeDotsWrap
          className="chat-message-three-dots"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <ThreeDotsIcon colorStroke="#C2C2C2" />
        </ThreeDotsWrap>

        {isPopoverOpen && (
          <PopOverWrap
            width={140}
            top={isPopupAbove ? '-40px' : '30px'} // Conditionally adjust top position
            left={messageRef.current?.offsetWidth > 250 ? -40 : -135}
          >
            <ListUserUserActions>
              <li onClick={onDeleteMessageClick}>{t('common:delete')}</li>
              {isAdmin && (
                <li onClick={onPinMessageClick}>
                  {isPinned ? t('common:unpinMessage') : t('common:pinMessage')}
                </li>
              )}
            </ListUserUserActions>
          </PopOverWrap>
        )}
      </MessageWrap>
      {messageType !== MESSAGE_TYPE.MESSAGE_RIGHT_SIMPLE_NO_TITLE_ITEM && (
        <TimeWrap>
          {isLoading && <PaperPlaneIcon />}
          <TimeText>{datetime}</TimeText>
        </TimeWrap>
      )}
    </MessageContainer>
  );
};
