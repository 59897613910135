import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  img?: string;
  isFullRounded?: boolean;
  mb?: number;
  cursor?: string;
  left?: number;
  width?: number;
  isPinned?: boolean;
  top?:any
}

export const MessageContainer = styled.div<IProps>`
  display: flex;
  width: 100%;
  height:auto;
  margin-bottom: ${({ isFullRounded }) => (isFullRounded ? '10px' : '6px')};
  align-items: center;
  position: relative;
  :hover {
    .chat-message-three-dots {
      display: flex;
    }
  }
`;

export const UserProfilePhotoWrap = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  align-self: flex-end;
  margin-right: 8px;
`;

export const UserProfilePhoto = styled.span<IProps>`
  display: block;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  ${({ img }) =>
    img &&
    `background: url(${img}) no-repeat;
     background-size: cover; background-position: center;`};
  cursor: ${({ cursor }) => cursor || 'auto'};
`;

export const MessageWrap = styled.div<IProps>`
  padding: 10px;
  color: ${style.mainGray.whiteSmokeColor};
  border-radius: ${({ isFullRounded }) =>
    isFullRounded ? '16px' : '16px 16px 16px 0'};
  background-color: ${({ isPinned }) =>
    isPinned ? style.mainOrange.darkOrange : style.mainGray.whiteSmokeColor};
  max-width: 84%;
`;

export const MessageTextWrapper = styled.p<IProps>`
  font-size: 13px;
  line-height: 130%;
  color: ${style.mainBlack.black};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const MessageHeaderText = styled(MessageTextWrapper)`
  line-height: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  cursor: pointer;
  margin-top: 5px;
`;

export const TimeText = styled.p`
  font-size: 12px;
  line-height: 100%;
  font-weight: 300;
  color: ${style.mainGray.SuvaGrey};
  margin-left: 50px;
  margin-bottom: 12px;
`;
export const ThreeDotsWrap = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: ${style.mainGray.snowColor};
  cursor: pointer;
`;

export const PopOverWrap = styled.div<IProps>`
  position: absolute;
  right: ${({ left }) => (left ? `${left}px` : 0)};
  z-index: 100;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  top: ${({ top }) => (top ? top : '30px')}; // Dynamically set top position
`;
export const PinIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  
`;
