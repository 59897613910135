export const stateKey = {
  loading: 'loading',
  data: 'data',
  error: 'error',
};

export const variant = {
  social: 'social',
  business: 'business',
  roadsharing: 'roadsharing',
  kitt: 'kitt',
  chat: 'chat',
};

export const methods = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

export const versions = {
  v01: 'v01',
  v02: 'v02',
  v05: 'v05',
  v10: 'v10',
  v11: 'v11',
  v12: 'v12',
};

export const endpoints = {
  [versions.v01]: process.env._DB_HOST,
  [versions.v02]: process.env._DB_HOST,
  [versions.v05]: process.env._DB_HOST,
  [versions.v10]: process.env._DB_HOST,
  [versions.v11]: process.env._DB_HOST,
  [versions.v12]: process.env._DB_HOSTv12,
  [variant.business]: process.env._MARKETPLACE_HOST,
  [variant.roadsharing]: process.env._ROADSHARING,
  [variant.kitt]: process.env._KITT_HOST,
  [variant.chat]: `${process.env._CHAT_BASE_API_URL}/`,
};

const endpointType = {
  AUTH_LOGIN: 'AUTH_LOGIN',
  AUTH_LOGIN_FACEBOOK: 'AUTH_LOGIN_FACEBOOK',
  AUTH_LOGIN_APPLE: 'AUTH_LOGIN_APPLE',
  AUTH_SIGNUP: 'AUTH_SIGNUP',
  AUTH_RESET_PASSWORD: 'AUTH_RESET_PASSWORD',
  AUTH_RSTORE_PASSWORD: 'AUTH_RSTORE_PASSWORD',
  TUTORIAL_CREATE_USERNAME: 'TUTORIAL_CREATE_USERNAME',
  TUTORIAL_GET_USER_INTEREST: 'TUTORIAL_GET_USER_INTEREST',
  TUTORIAL_POST_USER_INTEREST: 'TUTORIAL_POST_USER_INTEREST',
  TUTORIAL_ADD_CAR_BRANDS: 'TUTORIAL_ADD_CAR_BRANDS',
  TUTORIAL_GET_SUGGESTION: 'TUTORIAL_GET_SUGGESTION',
  TUTORIAL_COMPLETE: 'TUTORIAL_COMPLETE',
  GET_CARS_BRAND: 'GET_CARS_BRAND',
  USER_FOLLOW_USER: 'USER_FOLLOW_USER',
  USER_UNFOLLOW_USER: 'USER_UNFOLLOW_USER',
  USER_FOLLOW_GROUP: 'USER_FOLLOW_GROUP',
  USER_UNFOLLOW_GROUP: 'USER_UNFOLLOW_GROUP',
  USER_JOIN_GROUP: 'USER_JOIN_GROUP',
  USER_UNJOIN_GROUP: 'USER_UNJOIN_GROUP',

  USER_IDENTITY: 'USER_IDENTITY',
  USER_UPDATE: 'USER_UPDATE',
  USER_GET_GARAGE: 'USER_GET_GARAGE',

  // Donate
  DONATE_INFO: 'DONATE_INFO',
  DONATE_EVENT: 'DONATE_EVENT',

  // Feed page
  FEED_GET_NEARBY_DRIVERS: 'FEED_GET_NEARBY_DRIVERS',
  FEED_GET_FOLLOWING_POSTS: 'FEED_GET_FOLLOWING_POSTS',
  FEED_GET_DISCOVER_POSTS: 'FEED_GET_DISCOVER_POSTS',
  FEED_GET_EVENT_POSTS: 'FEED_GET_EVENT_POSTS',
  FEED_GET_BUSINESS_POSTS: 'FEED_GET_BUSINESS_POSTS',
  FEED_GET_SHOP_POSTS: 'FEED_GET_SHOP_POSTS',
  FEED_GET_FOLLOWING_CHECK_NEARBY: 'FEED_GET_FOLLOWING_CHECK_NEARBY',
  FEED_DELETE_POST: 'FEED_DELETE_POST',
  GET_FEED_ROUTES_POST: 'FEED_ROUTES_POST',
  SAVE_ROUTES_POST: 'SAVE_ROUTES_POST',

  // Posts Sensor
  POSTS_SENSOR: 'POSTS_SENSOR',

  // LINK POST
  CREATE_LINK_POST: 'CREATE_LINK_POST',
  EDIT_LINK_POST: 'EDIT_LINK_POST',

  // PHOTO POST
  CREATE_PHOTO_POST: 'CREATE_PHOTO_POST',
  UPDATE_PHOTO_POST: 'UPDATE_PHOTO_POST',

  // EVENT
  EVENT_ATTENDS_WITHOUT_VEHICLE: 'EVENT_ATTENDS_WITHOUT_VEHICLE',
  EVENT_ATTENDS_WITH_VEHICLE: 'EVENT_ATTENDS_WITH_VEHICLE',
  EVENT_CREATE: 'EVENT_CREATE',
  EVENT_EDIT: 'EVENT_EDIT',
  EVENT_GET_ADDENDED_VEHICLES: 'EVENT_GET_ADDENDED_VEHICLES',
  GET_EVENT_FORMS: 'GET_EVENT_FORMS',
  GET_USER_FORM_SUBMISSION: 'GET_USER_FORM_SUBMISSION',
  SUBMIT_EVENT_FORM: 'SUBMIT_EVENT_FORM',
  CONFIRM_USER: 'CONFIRM_USER',
  REJECT_USER: 'REJECT_USER',
  REMOVE_USER: 'REMOVE_USER',
  ACCEPT_USER: 'ACCEPT_USER',
  UPDATE_EVENT_CAP: 'UPDATE_EVENT_CAP',
  REMIND_USER: 'REMIND_USER',
  REMIND_ALL_USER: 'REMIND_ALL_USER',
  GET_EVENT_ATTENDING_STATUS: 'GET_EVENT_ATTENDING_STATUS',
  STRIPE_CHECKOUT: 'STRIPE_CHECKOUT',
  GET_SUBMISSION_REPORT: 'GET_SUBMISSION_REPORT',
  SELF_CONFIRM_ATTENDANCE: 'SELF_CONFIRM_ATTENDANCE',
  REFUND_ATTENDEE: 'REFUND_ATTENDEE',
  GET_WAIVER_TEMPLATES: 'GET_WAIVER_TEMPLATES',

  // User profile
  PROFILE_GET_POSTS: 'PROFILE_GET_POSTS',
  PROFILE_USER_FOLLOW: 'PROFILE_USER_FOLLOW',
  PROFILE_USER_UNFOLLOW: 'PROFILE_USER_UNFOLLOW',
  PROFILE_GET_GROUPS: 'PROFILE_GET_GROUPS',
  PROFILE_GET_USER_EVENTS: 'PROFILE_GET_USER_EVENTS',

  // Like/Unlike
  LIKE_POSTS: 'LIKE_POSTS',
  UNLIKE_POSTS: 'UNLIKE_POSTS',
  LIKE_VEHICLE: 'LIKE_VEHICLE',
  UNLIKE_VEHICLE: 'UNLIKE_VEHICLE',

  // Page sidebar
  SIDEBAR_GET_MY_EVENTS: 'SIDEBAR_GET_MY_EVENTS',
  SIDEBAR_GET_MY_GROUPS: 'SIDEBAR_GET_MY_GROUPS',
  SIDEBAR_GET_POPULAR_POSTS: 'SIDEBAR_GET_POPULAR_POSTS',

  // Business
  BUSINESS_SELLER_CONTACT: 'BUSINESS_SELLER_CONTACT',

  // Vehicles
  CAR_GET_CAR_BRANDS: 'CAR_GET_CAR_BRANDS',
  GET_CAR_MODELS: 'GET_CAR_MODELS',
  VEHICLE_CREATE_POST: 'VEHICLE_CREATE_POST',
  VEHICLE_EDIT_POST: 'VEHICLE_EDIT_POST',
  GET_CAR_TYPES: 'GET_CAR_TYPES',
  GET_VEHICLES: 'GET_VEHICLES',
  DELETE_VEHICLES: 'DELETE_VEHICLES',

  // Edit profile
  GET_USER_INTERESTS: 'GET_USER_INTERESTS',
  GET_USER_COLLABORATORS: 'GET_USER_COLLABORATORS',

  // SEARCH PAGE
  SEARCH_GROUP: 'SEARCH_GROUP',
  SEARCH_PROFILE: 'SEARCH_PROFILE',
  SEARCH_POSTS: 'SEARCH_POSTS',
  SEARCH_VEHICLES: 'SEARCH_VEHICLES',
  SEARCH_EVENTS: 'SEARCH_EVENTS',

  // GROUP PAGE
  GROUP_ATTEND_VEHICLE: 'GROUP_ATTEND_VEHICLE',
  GET_GROUP_VEHICLES: 'GET_GROUP_VEHICLES',
  GET_GROUP_POSTS: 'GET_GROUP_POSTS',
  GET_GROUP_ADMINS: 'GET_GROUP_ADMINS',
  CREATE_GROUP: 'CREATE_GROUP',
  EDIT_GROUP: 'EDIT_GROUP',
  GET_SAVED_GROUPS: 'GET_SAVED_GROUPS',

  // ROUTES
  GET_SAVED_ROUTES: 'GET_SAVED_ROUTES',
  ROUTES_ADD_FAVORITES: 'ROUTES_ADD_FAVORITES',
  ROUTES_DELETE_FAVORITES: 'ROUTES_DELETE_FAVORITES',
  ROUTES_PATCH_PRIVACY: 'ROUTES_PATCH_PRIVACY',

  // Landing
  LANDING_GET_EVENTS: 'LANDING_GET_EVENTS',
  LANDING_GET_ROUTES: 'LANDING_GET_ROUTES',
  LANDING_GET_GROUPS: 'LANDING_GET_GROUPS',

  // brand landing
  LANDING_GET_BRAND_POSTS: 'LANDING_GET_BRAND_POSTS',
  LANDING_GET_BRAND_GROUPS: 'LANDING_GET_BRAND_GROUPS',
  LANDING_GET_BRAND_VEHICLES: 'LANDING_GET_BRAND_VEHICLES',

  // campaign
  GET_CAMPAIGN: 'GET_CAMPAIGN',
  GET_CAMPAIGN_DETAIL: 'GET_CAMPAIGN_DETAIL',
  GET_REWARD_CAMPAIGN: 'GET_REWARD_CAMPAIGN',
  GET_REWARD_CAMPAIGN_DETAIL: 'GET_REWARD_CAMPAIGN_DETAIL',

  // Stripe
  INITIALIZE_STRIPE_ACCOUNT: 'INITIALIZE_STRIPE_ACCOUNT',
  GET_EVENT_STRIPE_ACCOUNT: 'GET_EVENT_STRIPE_ACCOUNT',
  // Club Page
  CLUB_WAIVER_POST: 'CLUB_WAIVER_POST',
  DELETE_CLUB_WAIVER: 'DELETE_CLUB_WAIVER',
  EDIT_CLUB_WAIVER: 'EDIT_CLUB_WAIVER',

  // Pin Message
  PIN_MESSAGE: 'PIN_MESSAGE',

  // Pin Group Post
  PIN_GROUP_POST: 'PIN_GROUP_POST',

  // REMOVE GROUP TAGS,
  REMOVE_GROUP_TAGS: 'REMOVE_GROUP_TAGS',
};

export default endpointType;
