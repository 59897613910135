import { Field } from 'react-final-form';

import { IField } from './IPermissionDrawer';
import InputField from './Input';

const EditClubWaiverDrawerField = (props: IField) => {
  return <Field component={InputField} {...props} />;
};

export default EditClubWaiverDrawerField;
