import { useRouter } from 'next/router';

import Drawer from 'components/Drawer';
import appState from 'hooks/appState';
import { APP_STATE } from 'types';
import { routesPath } from 'utils/constants/routePath';

import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import GroupForm from 'forms/GroupForm';
import { useState } from 'react';
import { getCorrectImageBase64Format } from 'utils/getCorrectImageBase64Format';
import { useDispatch } from 'react-redux';
import { postJoinGroupSuccess } from 'store/user/actions';

const CreateGroupDrawer = () => {
  const [createGroupLoading, setCreateGroupLoading] = useState(false);

  const router = useRouter();

  const appStateAction = appState();
  const { action, loading } = fetchApi();
  const dispatch = useDispatch();
  const onCreateGroup = async (v) => {    
    try {
      setCreateGroupLoading(true);
      const bodyData = {
        admins: v?.groupAdmins?.map((i) => i?._id) || [],
        brands: v?.groupBrands?.map((i) => i?._id),
        collaborators: v?.groupCollaborators?.map((i) => i?._id) || [],
        interests: v?.groupInterests?.map((i) => i?._id),
        username: v?.groupUsername,
        name: v?.groupFullname,
        chat: v?.chat,
        private: v?.private,
        broadcast: v?.broadcast,
        coverPhoto: getCorrectImageBase64Format(v?.coverPhoto),
        profilePhoto: getCorrectImageBase64Format(v?.profilePhoto),
        postPermission: v?.permission,
        privacy: v?.privacy,
        description: v?.description,
        location: v?.location,
        website: v?.groupWebsite,
        zone: v?.locality,
        waiverFile:v?.waiverFile,
        joiningManagement: v?.joiningManagement || 'auto',
        ...(v?.secretCode && {
          joiningCode: v?.secretCode || null,
        }),
      };
      const res = await action(API_NAME.CREATE_GROUP, { body: bodyData });
      dispatch(postJoinGroupSuccess(res?.data));
      if (res?.code === 200) {
        appStateAction(APP_STATE.CREATE_GROUP_DRAWER, {
          isOpen: false,
        });
        router.push(`${routesPath.group_details}/${res?.data?._id}`);
      }
      setCreateGroupLoading(false);
    } catch (error) {
      console.log(error);
      setCreateGroupLoading(false);
    }
  };

  return (
    <Drawer
      open
      onDrawerClose={() =>
        appStateAction(APP_STATE.CREATE_GROUP_DRAWER, {
          isOpen: false,
        })
      }
      loading={loading}
      rootForm
    >
      <GroupForm
        onSubmit={onCreateGroup}
        onIconClick={() =>
          appStateAction(APP_STATE.CREATE_GROUP_DRAWER, {
            isOpen: false,
          })
        }
        inProgress={createGroupLoading}
        initialValues={{
          locality: 0,
        }}
      />
    </Drawer>
  );
};

export default CreateGroupDrawer;
