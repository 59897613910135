export const actionTypes = {
  GET_CHATS_START: 'GET_CHATS_START',
  GET_CHATS_SUCCESS: 'GET_CHATS_SUCCESS',
  GET_CHATS_ERROR: 'GET_CHATS_ERROR',
  GET_CHATS_NEXT_START: 'GET_CHATS_NEXT_START',
  GET_CHATS_NEXT_SUCCESS: 'GET_CHATS_NEXT_SUCCESS',
  GET_CHATS_NEXT_ERROR: 'GET_CHATS_NEXT_ERROR',
  GET_MESSAGES_START: 'GET_MESSAGES_START',
  GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES_ERROR: 'GET_MESSAGES_ERROR',
  GET_CHAT_DETAILS_START: 'GET_CHAT_DETAILS_START',
  GET_CHAT_DETAILS_SUCCESS: 'GET_CHAT_DETAILS_SUCCESS',
  GET_CHAT_DETAILS_ERROR: 'GET_CHAT_DETAILS_ERROR',
  GET_CHAT_MEMBERS_START: 'GET_CHAT_MEMBERS_START',
  GET_CHAT_MEMBERS_SUCCESS: 'GET_CHAT_MEMBERS_SUCCESS',
  GET_CHAT_MEMBERS_ERROR: 'GET_CHAT_MEMBERS_ERROR',
  GET_MESSAGES_NEXT_START: 'GET_MESSAGES_NEXT_START',
  GET_MESSAGES_NEXT_SUCCESS: 'GET_MESSAGES_NEXT_SUCCESS',
  GET_MESSAGES_NEXT_ERROR: 'GET_MESSAGES_NEXT_ERROR',
  SEARCH_FOR_USERS_START: 'SEARCH_FOR_USERS_START',
  SEARCH_FOR_USERS_SUCCESS: 'SEARCH_FOR_USERS_SUCCESS',
  SEARCH_FOR_USERS_ERROR: 'SEARCH_FOR_USERS_ERROR',
  SEARCH_FOR_USERS_NEXT_START: 'SEARCH_FOR_USERS_NEXT_START',
  SEARCH_FOR_USERS_NEXT_SUCCESS: 'SEARCH_FOR_USERS_NEXT_SUCCESS',
  SEARCH_FOR_USERS_NEXT_ERROR: 'SEARCH_FOR_USERS_NEXT_ERROR',
  CREATE_SINGLE_CHAT_START: 'CREATE_SINGLE_CHAT_START',
  CREATE_SINGLE_CHAT_SUCCESS: 'CREATE_SINGLE_CHAT_SUCCESS',
  CREATE_SINGLE_CHAT_ERROR: 'CREATE_SINGLE_CHAT_ERROR',
  CLEAR_SINGLE_CHAT_REQ_STAT: 'CLEAR_SINGLE_CHAT_REQ_STAT',
  CREATE_MULTIPLE_CHAT_START: 'CREATE_MULTIPLE_CHAT_START',
  CREATE_MULTIPLE_CHAT_SUCCESS: 'CREATE_MULTIPLE_CHAT_SUCCESS',
  CREATE_MULTIPLE_CHAT_ERROR: 'CREATE_MULTIPLE_CHAT_ERROR',
  INSERT_NEW_MESSAGE: 'INSERT_NEW_MESSAGE',
  MOVE_CHAT_TO_TOP: 'MOVE_CHAT_TO_TOP',
  SET_SELECTED_CHAT_ID: 'SET_SELECTED_CHAT_ID',
  MARK_MESSAGE_AS_SENT: 'MARK_MESSAGE_AS_SENT',
  CHANGE_CHAT_SETTINGS_START: 'CHANGE_CHAT_SETTINGS_START',
  CHANGE_CHAT_SETTINGS_SUCCESS: 'CHANGE_CHAT_SETTINGS_SUCCESS',
  CHANGE_CHAT_SETTINGS_ERROR: 'CHANGE_CHAT_SETTINGS_ERROR',
  CLEAR_CHAT_MEMBERS: 'CLEAR_CHAT_MEMBERS',
  DELETE_CHAT_MESSAGE: 'DELETE_CHAT_MESSAGE',
  DELETE_CHAT_START: 'DELETE_CHAT_START',
  DELETE_CHAT_SUCCESS: 'DELETE_CHAT_SUCCESS',
  DELETE_CHAT_ERROR: 'DELETE_CHAT_ERROR',
  CLEAR_CHATS_DATA: 'CLEAR_CHATS_DATA',
  CLEAR_MESSAGES_REQ_STAT: 'CLEAR_MESSAGES_REQ_STAT',
  UPLOAD_CHAT_MEDIA_AWS: 'UPLOAD_CHAT_MEDIA_AWS',
  CHANGE_CHAT_STATUS: 'CHANGE_CHAT_STATUS',
  GET_ROOM_INFO_START: 'GET_ROOM_INFO_START',
  GET_ROOM_INFO_SUCCESS: 'GET_ROOM_INFO_SUCCESS',
  GET_ROOM_INFO_ERROR: 'GET_ROOM_INFO_ERROR',
  CLEAR_ROOM_INFO: 'CLEAR_ROOM_INFO',
  CLEAR_CREATED_CHAT_INFO: 'CLEAR_CREATED_CHAT_INFO',
  EDIT_MULTIPLE_CHAT_START: 'EDIT_MULTIPLE_CHAT_START',
  EDIT_MULTIPLE_CHAT_SUCCESS: 'EDIT_MULTIPLE_CHAT_SUCCESS',
  EDIT_MULTIPLE_CHAT_ERROR: 'EDIT_MULTIPLE_CHAT_ERROR',
  CLEAR_APP_CLUB_STATE: 'CLEAR_APP_CLUB_STATE',
  PIN_CHAT_MESSAGE: 'PIN_CHAT_MESSAGE',
};

// GET CHATS
export const clearAppClubState = () => ({
  type: actionTypes.CLEAR_APP_CLUB_STATE,
});

export const getChatsStart = (data) => ({
  type: actionTypes.GET_CHATS_START,
  data,
});

export const getChatsSuccess = (data) => ({
  type: actionTypes.GET_CHATS_SUCCESS,
  data,
});

export const getChatsError = (error) => ({
  type: actionTypes.GET_CHATS_ERROR,
  error,
});

// GET CHATS NEXT
export const getChatsNextStart = (data) => ({
  type: actionTypes.GET_CHATS_NEXT_START,
  data,
});

export const getChatsNextSuccess = (data) => ({
  type: actionTypes.GET_CHATS_NEXT_SUCCESS,
  data,
});

export const getChatsNextError = (error) => ({
  type: actionTypes.GET_CHATS_NEXT_ERROR,
  error,
});

// GET MESSAGES
export const getMessagesStart = (data) => ({
  type: actionTypes.GET_MESSAGES_START,
  data,
});

export const getMessagesSuccess = (data) => ({
  type: actionTypes.GET_MESSAGES_SUCCESS,
  data,
});

export const getMessagesError = (error) => ({
  type: actionTypes.GET_MESSAGES_ERROR,
  error,
});

// GET CHAT DETAILS
export const getChatDetailsStart = (data) => ({
  type: actionTypes.GET_CHAT_DETAILS_START,
  data,
});

export const getChatDetailsSuccess = (data) => ({
  type: actionTypes.GET_CHAT_DETAILS_SUCCESS,
  data,
});

export const getChatDetailsError = (error) => ({
  type: actionTypes.GET_CHAT_DETAILS_ERROR,
  error,
});

// GET CHAT MEMBERS
export const getChatMembersStart = (data) => ({
  type: actionTypes.GET_CHAT_MEMBERS_START,
  data,
});

export const getChatMembersSuccess = (data) => ({
  type: actionTypes.GET_CHAT_MEMBERS_SUCCESS,
  data,
});

export const getChatMembersError = (error) => ({
  type: actionTypes.GET_CHAT_MEMBERS_ERROR,
  error,
});

// GET MESSAGES NEXT
export const getMessagesNextStart = (data) => ({
  type: actionTypes.GET_MESSAGES_NEXT_START,
  data,
});

export const getMessagesNextSuccess = (data) => ({
  type: actionTypes.GET_MESSAGES_NEXT_SUCCESS,
  data,
});

export const getMessagesNextError = (error) => ({
  type: actionTypes.GET_MESSAGES_NEXT_ERROR,
  error,
});

// SEARCH FOR USERS
export const searchForUsersStart = (data) => ({
  type: actionTypes.SEARCH_FOR_USERS_START,
  data,
});

export const searchForUsersSuccess = (data) => ({
  type: actionTypes.SEARCH_FOR_USERS_SUCCESS,
  data,
});

export const searchForUsersError = (error) => ({
  type: actionTypes.SEARCH_FOR_USERS_ERROR,
  error,
});

// SEARCH FOR USERS NEXT
export const searchForUsersNextStart = (data) => ({
  type: actionTypes.SEARCH_FOR_USERS_NEXT_START,
  data,
});

export const searchForUsersNextSuccess = (data) => ({
  type: actionTypes.SEARCH_FOR_USERS_NEXT_SUCCESS,
  data,
});

export const searchForUsersNextError = (error) => ({
  type: actionTypes.SEARCH_FOR_USERS_NEXT_START,
  error,
});

// CREATE SINGLE CHAT
export const createSingleChatStart = (data) => ({
  type: actionTypes.CREATE_SINGLE_CHAT_START,
  data,
});

export const createSingleChatSuccess = (data) => ({
  type: actionTypes.CREATE_SINGLE_CHAT_SUCCESS,
  data,
});

export const createSingleChatError = (error) => ({
  type: actionTypes.CREATE_SINGLE_CHAT_ERROR,
  error,
});

export const clearCreatedChatInfo = () => ({
  type: actionTypes.CLEAR_CREATED_CHAT_INFO,
});

export const clearSingleChatReqStat = () => ({
  type: actionTypes.CLEAR_SINGLE_CHAT_REQ_STAT,
});

// CREATE MULTIPLE CHAT
export const createMultipleChatStart = (data) => ({
  type: actionTypes.CREATE_MULTIPLE_CHAT_START,
  data,
});

export const createMultipleChatSuccess = (data) => ({
  type: actionTypes.CREATE_MULTIPLE_CHAT_SUCCESS,
  data,
});

export const createMultipleChatError = (error) => ({
  type: actionTypes.CREATE_MULTIPLE_CHAT_ERROR,
  error,
});

// MOVE_CHAT_TO_TOP
export const moveChatToTop = (data) => ({
  type: actionTypes.MOVE_CHAT_TO_TOP,
  data,
});

// INSERT NEW MESSAGE
export const insertNewMessage = (data) => ({
  type: actionTypes.INSERT_NEW_MESSAGE,
  data,
});

// SET SELECTED CHAT ID
export const setSelectedChatId = (data) => ({
  type: actionTypes.SET_SELECTED_CHAT_ID,
  data,
});

// MARK MESSAGE AS SENT
export const markMessageAsSent = (data) => ({
  type: actionTypes.MARK_MESSAGE_AS_SENT,
  data,
});

// CHANGE CHAT SETTINGS ACTIONS
export const changeChatSettingsStart = (data) => ({
  type: actionTypes.CHANGE_CHAT_SETTINGS_START,
  data,
});

export const changeChatSettingsSuccess = () => ({
  type: actionTypes.CHANGE_CHAT_SETTINGS_SUCCESS,
});

export const changeChatSettingsError = (error) => ({
  type: actionTypes.CHANGE_CHAT_SETTINGS_ERROR,
  error,
});

// CLEAR_CHAT_MEMBERS ACTIONS
export const clearChatMembers = () => ({
  type: actionTypes.CLEAR_CHAT_MEMBERS,
});

// DELETE CHAT MESSAGE ACTIONS
export const deleteChatMessage = (data) => ({
  type: actionTypes.DELETE_CHAT_MESSAGE,
  data,
});

// DELETE CHAT ACTIONS
export const deleteChatStart = (data) => ({
  type: actionTypes.DELETE_CHAT_START,
  data,
});

export const deleteChatSuccess = (data) => ({
  type: actionTypes.DELETE_CHAT_SUCCESS,
  data,
});

export const deleteChatError = (error) => ({
  type: actionTypes.DELETE_CHAT_ERROR,
  error,
});

// CLEAR CHATS DATA
export const clearChatsData = () => ({
  type: actionTypes.CLEAR_CHATS_DATA,
});

// CLEAR MESSAGES REQ STAT ACTIONS
export const clearMessagesReqStat = () => ({
  type: actionTypes.CLEAR_MESSAGES_REQ_STAT,
});

// UPLOAD CHAT MEDIA AWS
export const uploadChatMediaAws = (data) => ({
  type: actionTypes.UPLOAD_CHAT_MEDIA_AWS,
  data,
});

// CHANGE CHAT STATUS
export const changeChatStatus = (data) => ({
  type: actionTypes.CHANGE_CHAT_STATUS,
  data,
});

// GET ROOM INFO
export const getRoomInfoStart = (data) => ({
  type: actionTypes.GET_ROOM_INFO_START,
  data,
});

export const getRoomInfoSuccess = (data) => ({
  type: actionTypes.GET_ROOM_INFO_SUCCESS,
  data,
});

export const getRoomInfoError = (error) => ({
  type: actionTypes.GET_ROOM_INFO_ERROR,
  error,
});

export const clearRoomInfo = () => ({
  type: actionTypes.CLEAR_ROOM_INFO,
});

// EDIT MULTIPLE CHAT
export const editMultipleChatStart = (data) => ({
  type: actionTypes.EDIT_MULTIPLE_CHAT_START,
  data,
});

export const editMultipleChatSuccess = (data) => ({
  type: actionTypes.EDIT_MULTIPLE_CHAT_SUCCESS,
  data,
});

export const editMultipleChatError = (error) => ({
  type: actionTypes.EDIT_MULTIPLE_CHAT_ERROR,
  error,
});
export const pinChatMessage = (messageId, pin) => ({
  type: actionTypes.PIN_CHAT_MESSAGE,
  payload: { messageId, pin }, // Send payload with messageId and pin status
});

