import React from 'react';
import { format } from 'date-fns';
import {
  SectionHeader,
  SectionWrapper,
  Text,
} from './CreatePaymentFormDrawer.styles';

import moment, { Moment } from 'moment';
import { minimalTimezoneSet } from 'node_modules/compact-timezone-list';
import { FieldCollection, IFieldCollection } from 'components/DynamicFormComponents/FieldCollection/FieldCollection';
import { useTranslation } from 'react-i18next';


interface PaymentFormPreviewProps {
  description: string;
  currency: string;
  startDate: Moment;
  endDate: Moment;
  timeZone: string;
  refundDeadLine: Moment;
  fieldCollections: IFieldCollection[];
}

const PaymentFormPreview: React.FC<PaymentFormPreviewProps> = ({
  description,
  currency,
  startDate,
  endDate,
  timeZone,
  fieldCollections,
  refundDeadLine,
}) => {
  const {t} = useTranslation()

  const getTimezone = () => {
    const minimalTimeOne = minimalTimezoneSet.find(
      (d) => d?.offset === timeZone
    );
    if (!minimalTimeOne) return '';

    const { offset, tzCode, label } = minimalTimeOne;
    const tzAbbrMoment = moment.tz(tzCode).format('z');
    const isAbbrValid = !tzAbbrMoment.match(/[+-]/);

    return isAbbrValid ? `(GMT${offset}) ${tzAbbrMoment}` : label;
  };

  return (
    <div>
      <SectionWrapper>
        <SectionHeader>{t('common:description')}</SectionHeader>
        <Text>{description}</Text>
      </SectionWrapper>

      <SectionWrapper>
        <SectionHeader>{t('event:registrationDates')}</SectionHeader>
        <Text>{t('common:opens')}: {format(startDate.toDate(), 'MMM d, yyyy | h:mm a')}</Text>
        <Text>{t('common:closes')}: {format(endDate.toDate(), 'MMM d, yyyy | h:mm a')}</Text>
        <Text>{t('timePicker:timezone')}: {getTimezone()}</Text>
        {refundDeadLine && moment(refundDeadLine).isValid() && (
          <Text>
            {t('timePicker:refundDeadline')}:{' '}
            {format(refundDeadLine.toDate(), 'MMM d, yyyy | h:mm a')}
          </Text>
        )}
      </SectionWrapper>

      <SectionWrapper>
        <SectionHeader>{t('event:selectCurrency')}</SectionHeader>
        <Text>{currency}</Text>
      </SectionWrapper>

      <SectionWrapper>
        <Text>{t('event:questions')}</Text>
        {fieldCollections.map((collection, index) => (
        <FieldCollection
            readOnly
            currency={currency}
            collection={collection}
            key={`${collection.name}-${index}`}
          />
        ))}
      </SectionWrapper>
    </div>
  );
};

export default PaymentFormPreview;
