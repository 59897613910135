import React, { useState, useEffect } from 'react';
import { Button, Input, Upload, Space, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Drawer from 'components/Drawer';
import {
  StyledCard,
  FieldsContainer,
  ErrorMessage,
} from './CreateWaiver.styles';
import {
  FormFieldType,
  FormType,
  IFormField,
} from 'components/DynamicFormComponents/FormField/FormField';
import WaiverSelector from './WaiverSelector';
import WaiverFields from './WaiverFields';
import SelectedWaiverPreview from './SelectedWaiverPreview';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const DEFAULT_FIELDS: IFormField[] = [
  {
    name: 'firstName',
    label: 'firstName',
    type: FormFieldType.TextBox,
    required: true,
  },
  {
    name: 'lastName',
    label: 'lastName',
    type: FormFieldType.TextBox,
    required: true,
  },
  {
    name: 'signature',
    label: 'signature',
    type: FormFieldType.SIGNATURE,
    required: true,
  },
  {
    name: 'date',
    label: 'date',
    type: FormFieldType.DatePicker,
    required: true,
  },
];

const WaiverForm = ({ form, open, setOpen, initialValues }) => {
  const { t } = useTranslation('createWaiver');
  const userInfo = useSelector((state) => state?.user?.userDetails);
  const [selectedWaiver, setSelectedWaiver] = useState('');
  const [file, setFile] = useState(null);
  const [fields, setFields] = useState<IFormField[]>(DEFAULT_FIELDS);
  const [previewUrl, setPreviewUrl] = useState('');
  const [waiverSelected, setWaiverSelected] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newFieldName, setNewFieldName] = useState('');
  const [error, setError] = useState(null);
  const { action } = fetchApi();
  const [waiverTemplates, setWaiverTemplates] = useState<string[]>([]);

  const eventId = initialValues?._id;
  const waiverForm = initialValues?.forms?.find(
    (el) => el?.type === FormType.Waiver
  );
  const formId = waiverForm?.id;

  useEffect(() => {
    const fetchWaiverTemplates = async () => {
      try {
        const response = await action(API_NAME.GET_WAIVER_TEMPLATES, {
          params: {
            id: initialValues?.group ? initialValues?.group : userInfo?._id,
          },
        });

        if (response?.waiverTemplates) {
          setWaiverTemplates(response.waiverTemplates);
        }
      } catch (error) {
        console.error('Failed to fetch waiver templates:', error);
      }
    };

    fetchWaiverTemplates();
  }, [action, userInfo]);

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await action(API_NAME.GET_EVENT_FORMS, {
          params: { eventId, formId },
        });

        if (response?.data) {
          // Find fields that are not in DEFAULT_FIELDS
          const additionalFields = response.data.fieldCollections?.flatMap(
            (item) =>
              item?.fields?.filter(
                (field) =>
                  !DEFAULT_FIELDS.some(
                    (defaultField) => defaultField.name === field.name
                  )
              )
          );
          // Update state with DEFAULT_FIELDS and only the additional fields
          setFields([...DEFAULT_FIELDS, ...(additionalFields || [])]);
          response?.data?.waiverTemplate &&
            handleWaiverSelect(response?.data?.waiverTemplate?._id);
        }
      } catch (error) {
        console.error('Failed to fetch form data:', error);
      }
    };

    if (open && eventId && formId) {
      fetchFormData();
    }
  }, [open, eventId, formId, action]);

  const updateFile = (info) => {
    const { status, originFileObj } = info.file;

    if (status === 'done') {
      setFile(originFileObj);
      setPreviewUrl(URL.createObjectURL(originFileObj));
      setWaiverSelected(true);
      resetSelections();
    } else if (status === 'error') {
      setError(`${info.file.name} ${t('uploadFailed')}`);
    }
  };

  const handleWaiverSelect = (value: string) => {
    const matchedValue: any = waiverTemplates?.find(
      (e: any) => e?._id === value
    );
    setSelectedWaiver(value);
    setPreviewUrl(
      matchedValue?.url?.includes('https')
        ? matchedValue?.url
        : URL.createObjectURL(matchedValue?.url)
    );
    setWaiverSelected(true);
  };

  const resetSelections = () => {
    setSelectedWaiver('');
    setError(null);
  };

  const prepareWaiverData = async () => {
    if (!waiverSelected || (!file && !selectedWaiver)) {
      setError(t('selectOrUploadWaiver'));
      return null;
    }

    const fieldCollections = fields.map(({ name, type, label, required }) => ({
      fields: [{ name, type, label, required }],
    }));

    if (file) {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        setError(t('onlyPdfOrDocFiles'));
        return null;
      }

      const base64String = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve((reader.result as string).split(',')[1]);
        reader.onerror = () => reject('File reading error');
        reader.readAsDataURL(file);
      });

      return {
        fieldCollections,
        type: 'waiver',
        waiverFile: {
          content: base64String,
          name: file.name,
          type: file.type,
          size: file.size,
        },
      };
    }

    return {
      fieldCollections,
      type: 'waiver',
      waiverTemplate: selectedWaiver,
    };
  };

  const handleSubmit = async () => {
    try {
      const waiverData = await prepareWaiverData();
      if (waiverData) {
        form.change('waiver', waiverData);
        setError(null);
        setOpen(false);
      }
    } catch (err) {
      console.error('Submission error:', err);
      setError(t('submissionError'));
    }
  };

  const handleAddField = () => {
    if (newFieldName.trim()) {
      setFields((prevFields) => [
        ...prevFields,
        {
          label: newFieldName.trim(),
          type: FormFieldType.TextBox,
          name: newFieldName.trim().toLowerCase().replace(/\s+/g, '_'),
          required: false,
        },
      ]);
      setNewFieldName('');
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setWaiverSelected(false);
    setFile(null);
    setSelectedWaiver('');
    setPreviewUrl('');
    setError(null);
  };

  return (
    <Drawer
      open={open}
      onDrawerClose={() => setOpen(false)}
      headerText={t('headerText')}
      onIconClick={() => setOpen(false)}
      submitText={t('submitText')}
      onSubmit={handleSubmit}
    >
      {error && (
        <ErrorMessage>
          <ExclamationCircleOutlined /> {error}
        </ErrorMessage>
      )}

      {!waiverSelected ? (
        <WaiverSelector
          updateFile={updateFile}
          selectedWaiver={selectedWaiver}
          waiverTemplates={waiverTemplates}
          setSelectedWaiver={handleWaiverSelect}
          setWaiverSelected={setWaiverSelected}
        />
      ) : (
        <SelectedWaiverPreview
          previewUrl={previewUrl}
          handleCancel={handleCancel}
        />
      )}

      <WaiverFields
        fields={fields}
        setFields={setFields}
        setIsModalVisible={setIsModalVisible}
      />

      <Modal
        title={t('addNewField')}
        open={isModalVisible}
        onOk={handleAddField}
        onCancel={() => setIsModalVisible(false)}
      >
        <Input
          value={newFieldName}
          placeholder={t('enterFieldName')}
          onChange={(e) => setNewFieldName(e.target.value)}
        />
      </Modal>
    </Drawer>
  );
};

export default WaiverForm;
