import React, { useCallback, useEffect } from 'react';

import { CrossIcon } from 'public/assets/icons';
import { style } from 'utils/constants/style';
import { IFormNotifyModal } from './IFormNotifyModal';
import {
  ContentWrap,
  ModalWrap,
  HeaderText,
  BodyText,
  CloseIconWrap,
} from './FormNotifyModal.style';
import { RectangleButton } from '../../index';
import { useTranslation } from 'react-i18next';
import { AttenderStatus } from 'types';

export const FormNotifyModal = ({ status, onClose }: IFormNotifyModal) => {
  const { t } = useTranslation('event');

  const onModalWrapClick = (e) => {
    e.stopPropagation();
  };

  const getModalContent = () => {
    switch (status) {
      case AttenderStatus.Attending:
        return {
          headerText: t('youAreConfirmed'),
          bodyText: t('confirmDesc'),
        };
      case AttenderStatus.Waitlisted:
        return {
          headerText: t('youAreWaitlisted'),
          bodyText: t('waitlistDesc'),
        };
      case AttenderStatus.PendingRegistrationForm:
        return {
          headerText: t('pendingFormRegistration'),
          bodyText: t('pendingRegDesc'),
        };
      case AttenderStatus.PendingRegistration:
        return {
          headerText: t('pending_Registration'),
          bodyText: t('pendingRegDesc'),
        };
      case AttenderStatus.PendingPaymentForm:
        return {
          headerText: t('pendingFormPayment'),
          bodyText: t('pendingPayDesc'),
        };
      case AttenderStatus.PendingPayment:
        return {
          headerText: t('pending_Payment'),
          bodyText: t('pendingPayDesc'),
        };
      case AttenderStatus.PendingApproval:
        return {
          headerText: t('pending_Approval'),
          bodyText: t('pendingApprovalDesc'),
        };
      case AttenderStatus.PendingWaiver:
        return {
          headerText: t('pendingWaiver'),
          bodyText: t('pleaseFillTheWaiver'),
        };
      case AttenderStatus.StripeOnboarding:
        return {
          headerText: t('readAndReview'),
          bodyText: t('redirecToStripe'),
        };
      case AttenderStatus.PendingHostApproval:
        return {
          headerText: t('pending_Approval'),
          bodyText: t('pendingApprovalDesc'),
        };
      default:
        return {
          headerText: '',
          bodyText: '',
        };
    }
  };
  const { headerText, bodyText } = getModalContent();

  const hideModal = !status || status === AttenderStatus.ReadyToConfirm;

  useEffect(() => {
    if (hideModal) {
      onClose();
    }
  }, [hideModal, onClose]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.overflowX = 'hidden';
    };
  }, []);

  return (
    <ContentWrap onClick={onClose}>
      <ModalWrap onClick={onModalWrapClick}>
        <HeaderText>{headerText}</HeaderText>
        <BodyText>{bodyText}</BodyText>

        <RectangleButton
          text={'OK'}
          color={style.mainWhite.white}
          backgroundColor={style.mainYellow.yellow}
          onClick={onClose}
        />

        <CloseIconWrap onClick={onClose}>
          <CrossIcon color="#8D8D8D" />
        </CloseIconWrap>
      </ModalWrap>
    </ContentWrap>
  );
};
