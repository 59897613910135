import i18n from 'utils/i18n';

export const MESSAGE_TYPE = {
  DATE_ITEM: 1,
  MESSAGE_LEFT_SIMPLE_ITEM: 2,
  MESSAGE_LEFT_SIMPLE_NO_TITLE_ITEM: 3,
  MESSAGE_LEFT_COMPLETED_ITEM: 4,
  MESSAGE_LEFT_COMPLETED_NO_TITLE_ITEM: 5,
  MESSAGE_RIGHT_ITEM: 6,
  MESSAGE_RIGHT_COMPLETED_NO_TITLE_ITEM: 7,
  MESSAGE_RIGHT_COMPLETED_ITEM: 8,
  MESSAGE_RIGHT_SIMPLE_NO_TITLE_ITEM: 9,
  MESSAGE_RIGHT_MEDIA_ITEM: 10,
  MESSAGE_LEFT_MEDIA_ITEM: 11,
};

export const isRockr = process.env._ENV === 'rockr';

export const BASE_SITE_HOST = isRockr
  ? 'https://www.rockr.io'
  : 'https://www.roadstr.io';

// Server returns default image sometimes so we hardcode it's url
export const DEFAULT_LINK_POST_PLACEHOLDER_IMAGE =
  'https://cdn.roadstr.io/linkPreview_default_img.png';

// TAGS FOR SEARCH SCREEN
export const SEARCH_TAGS = () => [
  [
    {
      key: 'all',
      title: i18n.t('search:allFilter'),
    },
    {
      key: 'nearby',
      title: i18n.t('search:nearbyFilter'),
    },
    {
      key: 'brands',
      title: i18n.t('search:brandsFilter'),
    },
  ],
  [
    {
      key: 'all',
      title: i18n.t('search:allFilter'),
    },
    {
      key: 'nearby',
      title: i18n.t('search:nearbyFilter'),
    },
  ],
  [
    {
      key: 'all',
      title: i18n.t('search:allFilter'),
    },
    {
      key: 'nearby',
      title: i18n.t('search:nearbyFilter'),
    },
    {
      key: 'photos',
      title: i18n.t('search:photosFilter'),
    },
    {
      key: 'links',
      title: i18n.t('search:linksFilter'),
    },
    {
      key: 'videos',
      title: i18n.t('search:videosFilter'),
    },
  ],
  [
    {
      key: 'all',
      title: i18n.t('search:allFilter'),
    },
    {
      key: 'nearby',
      title: i18n.t('search:nearbyFilter'),
    },
    {
      key: 'brands',
      title: i18n.t('search:brandsFilter'),
    },
    {
      key: 'models',
      title: i18n.t('search:modelsFilter'),
    },
  ],
  [
    {
      key: 'all',
      title: i18n.t('search:allFilter'),
    },
    {
      key: 'calendar',
      title: i18n.t('search:calendarFilter'),
    },
    {
      key: 'nearby',
      title: i18n.t('search:nearbyFilter'),
    },
  ],
];

export const APP_CONFIG = {
  appname: isRockr ? 'rockr' : 'roadstr',
  APPNAME: isRockr ? 'RocKr' : 'RoadStr',
  vehicle: isRockr ? 'bike' : 'car',
  VEHICLE: isRockr ? 'Bike' : 'Car',
};

export const ERROR_MESSAGES = () => ({
  scheduledPostError: i18n.t('error:theDateShouldBeAFutureDate'),
});

export const InvolvedUserTypes = {
  MY_FOLLOWERS: 'MY_FOLLOWERS',
  MY_FOLLOWING: 'MY_FOLLOWING',
  USER_FOLLOWERS: 'USER_FOLLOWERS',
  USER_FOLLOWING: 'USER_FOLLOWING',
  GROUP_FOLLOWERS: 'GROUP_FOLLOWERS',
  GROUP_MEMBERS: 'GROUP_MEMBERS',
  GROUP_ADMINS: 'GROUP_ADMINS',
  EVENT_ATTENDING: 'EVENT_ATTENDING',
  EVENT_INVITED: 'EVENT_INVITED',
  EVENT_WAITLISTING: 'EVENT_WAITLISTING',
};

export const FollowingStatus = {
  NOT_FOLLOWING: 0,
  REQUESTED: 1,
  FOLLOWING: 2,
};

export const WaitlistingStatus = {
  NOTIFY: 0,
  NOTIFIED: 1,
};

export const MapSidebarTypes = {
  SAVED_ROUTES: 'SAVED_ROUTES',
  WHERE_TO: 'WHERE_TO',
  PLAN_ROUTE: 'PLAN_ROUTE',
  CHOSE_START_POINT: 'CHOSE_START_POINT',
  CHOSE_END_POINT: 'CHOSE_END_POINT',
  CHOSE_WAY_POINT: 'CHOSE_WAY_POINT',
  ROUTE_DETAILS: 'ROUTE_DETAILS',
  CHOOSE_ROUTE_METHOD: 'CHOOSE_ROUTE_METHOD',
  IMPORT_ROUTE: 'IMPORT_ROUTE',
};

export const MapRoutePointTypes = {
  START: 'START',
  END: 'END',
  WAY: 'WAY',
};

export const START_WAYPOINT_TYPE = 'start.waypoint';
export const FINISH_WAYPOINT_TYPE = 'finish.waypoint';

export const DEFAULT_PAGE_SIZE = 20;

export const eventOwnerShipVariants = {
  REALOWNER: 'realOwner',
  NOTREALOWNER: 'notRealOwner',
  NOTCHOOSEN: 'notChoosen',
};

export const EventTypeNames = {
  CARS_SHOWS: 'carshows',
  DRIVES: 'drives',
  MEETUPS: 'meetups',
  RACES: 'races',
  TRACK_DAYS: 'trackdays',
  OTHERS: 'otros',
  CHARITY_UKRAINE: 'charityUkraine',
};

export const POI_EVENT_TYPES = {
  NONE: -1,
  OTHER: 0,
  PHOTO_SPOT: 2,
  ROUTE: 1,
};

export const convertPinIds = (id: string) => {
  // return String(id)?.replace(/-/g, '_');
  return id;
};

export const MAP_FILTER_DATA = [
  {
    label: 'meetups',
    icon: 'meetups',
    layer: convertPinIds(process.env._EVENT_PIN_LAYER_NAME),
    id: process.env._EVENT_PIN_LAYER_NAME,
    iconProps: {},
    filterMatch: EventTypeNames.MEETUPS,
    filterKey: 'pinType',

    filterForClub: true,
  },
  {
    label: 'drives',
    icon: 'drives',
    layer: convertPinIds(process.env._EVENT_PIN_LAYER_NAME),
    id: process.env._EVENT_PIN_LAYER_NAME,
    iconProps: {},
    filterMatch: EventTypeNames.DRIVES,
    filterKey: 'pinType',
    filterForClub: true,
  },
  {
    label: 'races',
    icon: 'racePin',
    layer: convertPinIds(process.env._EVENT_PIN_LAYER_NAME),
    id: process.env._EVENT_PIN_LAYER_NAME,
    iconProps: {},
    filterMatch: EventTypeNames.RACES,
    filterKey: 'pinType',

    filterForClub: true,
  },
  {
    label: 'trackDays',
    icon: 'eventPin',
    layer: convertPinIds(process.env._EVENT_PIN_LAYER_NAME),
    id: process.env._EVENT_PIN_LAYER_NAME,
    iconProps: {},
    filterMatch: EventTypeNames.TRACK_DAYS,
    filterKey: 'pinType',

    filterForClub: true,
  },
  {
    label: 'carShows',
    icon: 'carShowPin',
    layer: convertPinIds(process.env._EVENT_PIN_LAYER_NAME),
    id: process.env._EVENT_PIN_LAYER_NAME,
    iconProps: {},
    filterMatch: EventTypeNames.CARS_SHOWS,
    filterKey: 'pinType',

    filterForClub: true,
  },
  {
    label: 'others',
    icon: 'otherPin',
    layer: convertPinIds(process.env._EVENT_PIN_LAYER_NAME),
    id: process.env._EVENT_PIN_LAYER_NAME,
    iconProps: {},
    filterMatch: EventTypeNames.OTHERS,
    filterKey: 'pinType',
    filterForClub: true,
  },
  {
    label: 'sharedRoutes',
    icon: 'sharedRoutePin',
    layer: convertPinIds(process.env._ROUTE_PIN_LAYER_NAME),
    id: process.env._ROUTE_PIN_LAYER_NAME,
    iconProps: {},
    toggleFullLayer: false,
    defaultFilterKey: 'distance',
    filterForClub: true,
    showNonClubData: true,
    defaultFilter: ['>', ['get', 'distance'], 8047],
  },
  {
    label: 'roads',
    icon: 'routePin',
    layer: convertPinIds(process.env._POI_PIN_LAYER_NAME),
    id: process.env._POI_PIN_LAYER_NAME,
    iconProps: {},
    filterKey: 'type',
    filterMatch: POI_EVENT_TYPES.ROUTE,
    filterForClub: true,
    showNonClubData: true,
  },
  {
    label: 'spots',
    icon: 'spotPin',
    layer: convertPinIds(process.env._POI_PIN_LAYER_NAME),
    id: process.env._POI_PIN_LAYER_NAME,
    iconProps: {},
    filterKey: 'type',
    filterMatch: POI_EVENT_TYPES.PHOTO_SPOT,
    filterForClub: true,
    showNonClubData: true,
  },
  {
    label: 'otherPOI',
    icon: 'otherPOIPin',
    layer: convertPinIds(process.env._POI_PIN_LAYER_NAME),
    id: process.env._POI_PIN_LAYER_NAME,
    iconProps: {},
    filterKey: 'type',
    filterMatch: POI_EVENT_TYPES.OTHER,
    filterForClub: true,
    showNonClubData: true,
  },
  {
    label: 'business',
    icon: 'businessPin',
    layer: convertPinIds(process.env._BUSINESS_PIN_LAYER_NAME),
    id: process.env._BUSINESS_PIN_LAYER_NAME,
    iconProps: {},
    toggleFullLayer: true,
    filterForClub: true,
    showNonClubData: true,
  },
];

export const FEED_FILTERS_TYPE = {
  ALL: 'all',
  MY_GROUPS: 'groupIds',
  LOCATION: 'zone',
  TYPE: 'types',
  POPULAR: 'popular',
  FOLLOWING: 'following',
  NEARBY: 'nearBy',
  DATE: 'date',
  GEO_LOCATION: 'location',
  LAT: 'lat',
  LNG: 'lng',
};

export const ROUTES_DISTANCE: any = {
  ID: '3',
  DISTANCE: 100000,
  LABEL: '<100 km',
  OPERATOR: 'lt',
};
export const MY_EVENT_STATUS: any = {
  HOSTING: 'Hosting',
  ATTENDING: 'ATTENDING',
  INVITED: 'INVITED',
  PAST: 'PAST',
};
export const FEED_FILTER_DATE_FORMAT = 'YYYY-MM-DD';
export const APP_TIMEZONE_FORMAT = 'DD MMM YYYY hh:mmA';
