/* eslint-disable no-unused-expressions */
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  getUserNotifications,
  acceptPendingRequest,
  declinePendingRequest,
} from 'services';
import {
  actionTypes,
  getUserNotificationsSuccess,
  getUserNotificationsError,
  getUserPendingNotificationsError,
  getUserPendingNotificationsSuccess,
  acceptPendingRequestSuccess,
  acceptPendingRequestError,
  declinePendingRequestSuccess,
  declinePendingRequestError,
} from './actions';

function* workerGetUserNotifications(action) {
  try {
    const { pending } = action.data;
    let params;

    if (pending) {
      params = {
        start: 0,
        limit: 0,
        pending: true,
      };
    } else {
      params = { ...action.data };
    }

    const resp = yield call(getUserNotifications, params);

    if (resp?.code === 200) {
      action.data.pending
        ? yield put(getUserPendingNotificationsSuccess(resp.data))
        : yield put(getUserNotificationsSuccess(resp.data));
    } else {
      action.data.pending
        ? yield put(getUserPendingNotificationsError(resp.data))
        : yield put(getUserNotificationsError(resp.data));
    }
  } catch (err) {
    yield put(getUserNotificationsError(err));
  }
}

function* workerAcceptPendingRequest(action) {
  try {
    const resp = yield call(acceptPendingRequest, action.data);

    console.log('pending accept resp', resp);

    resp.status === 204 || resp.code === 200
      ? yield put(acceptPendingRequestSuccess(resp.data))
      : yield put(acceptPendingRequestError(resp.data));
  } catch (err) {
    yield put(acceptPendingRequestError(err));
  }
}

function* workerDeclinePendingRequest(action) {
  try {
    const resp = yield call(declinePendingRequest, action.data);

    console.log('pending decline resp', resp);

    resp.status === 204 || resp.code === 200
      ? yield put(declinePendingRequestSuccess(resp.data))
      : yield put(declinePendingRequestError(resp.data));
  } catch (err) {
    yield put(declinePendingRequestError(err));
  }
}

function* watchNotifications() {
  yield takeEvery(
    actionTypes.GET_USER_NOTIFICATIONS_START,
    workerGetUserNotifications
  );
  yield takeEvery(
    actionTypes.ACCEPT_PENDING_REQUEST_START,
    workerAcceptPendingRequest
  );
  yield takeEvery(
    actionTypes.DECLINE_PENDING_REQUEST_START,
    workerDeclinePendingRequest
  );
}

export default watchNotifications;
