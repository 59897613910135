import { IFieldCollection } from 'components/DynamicFormComponents/FieldCollection/FieldCollection';
import { FormFieldType } from 'components/DynamicFormComponents/FormField/FormField';
import cloneDeep from 'lodash/cloneDeep';

export enum PaymentCollection {
  EntryFee = 'entryFee',
  GuestEntryFee = 'guestEntryFee',
  Insurance = 'insurance',
  Accomodation = 'accomodation',
  CharitableContribution = 'charitableContribution',
}

export const entryFeeCollection: IFieldCollection = {
  name: PaymentCollection.EntryFee,
  mustEditBeforeSubmit: true,
  fields: [
    {
      name: 'entryFee',
      type: FormFieldType.Checkbox,
      label: 'entryFee',
      required: true,
      monetaryValue: 50,
    },
  ],
};

export const insuranceCollection: IFieldCollection = {
  name: PaymentCollection.Insurance,
  mustEditBeforeSubmit: true,
  fields: [
    {
      name: 'insurance',
      type: FormFieldType.Checkbox,
      label: 'insurance',
      required: true,
      monetaryValue: 100,
    },
  ],
};

export const accomodationCollection: IFieldCollection = {
  name: PaymentCollection.Accomodation,
  mustEditBeforeSubmit: true,
  fields: [
    {
      name: 'accomodation',
      type: FormFieldType.MultipleOptions,
      label: 'accomodation',
      required: true,
      options: [
        { label: 'Single Room', value: 'single_room', monetaryValue: 1500 },
        { label: 'Double Room', value: 'double_room', monetaryValue: 2000 },
      ],
    },
  ],
};

export const charitableContributionCollection: IFieldCollection = {
  name: PaymentCollection.CharitableContribution,
  mustEditBeforeSubmit: true,
  fields: [
    {
      name: 'charitableContribution',
      type: FormFieldType.Checkbox,
      label: 'charitableContribution',
      required: true,
      monetaryValue: 20,
    },
  ],
};

export const guestEntryFeeCollection: IFieldCollection = {
  name: PaymentCollection.GuestEntryFee,
  mustEditBeforeSubmit: true,
  fields: [
    {
      name: 'guestEntryFee',
      type: FormFieldType.Checkbox,
      label: 'guestEntryFee',
      required: true,
      monetaryValue: 50,
    },
  ],
};

export const getCollectionByEnum = (
  collectionEnum: PaymentCollection
): IFieldCollection | undefined => {
  switch (collectionEnum) {
    case PaymentCollection.EntryFee:
      return cloneDeep(entryFeeCollection);

    case PaymentCollection.Insurance:
      return cloneDeep(insuranceCollection);

    case PaymentCollection.Accomodation:
      return cloneDeep(accomodationCollection);

    case PaymentCollection.CharitableContribution:
      return cloneDeep(charitableContributionCollection);

    case PaymentCollection.GuestEntryFee:
      return cloneDeep(guestEntryFeeCollection);

    default:
      return undefined;
  }
};
